import TicketToggler from './TicketToggler'
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {formatMoney} from '../../../../_helpers/_helpers'
import {eventsStore} from '../../events/core/eventsStore'
import {BookingTicketModel} from '../core/bookingsStore'
import {useAuthStore} from '../../auth/core/authStore'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  form: any
  index: number
  bookingTicket: BookingTicketModel
}
const BookingTicketItem: FC<Props> = ({form, index, bookingTicket}) => {
  const {isLoggedIn} = useAuthStore()
  const {publicEvent} = eventsStore()

  let showLeft = false

  if (
    bookingTicket.ticket.qty - bookingTicket.ticket.bookedTickets <= 10 &&
    bookingTicket.ticket.canBook.code !== 'ticketSoldOut'
  ) {
    showLeft = true
  }

  const handleChange = (e: any) => {
    if (parseFloat(e.target.value) < bookingTicket.ticket.price || e.target.value === '') {
      form.setFieldValue(`bookingTickets[${index}.value`, bookingTicket.ticket.price)
    } else {
      form.setFieldValue(`bookingTickets[${index}.value`, parseFloat(e.target.value))
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (bookingTicket.ticket.enableVariablePrice) {
      form.setFieldValue(`bookingTickets[${index}].qty`, 1)
      form.setFieldValue(`bookingTickets[${index}].value`, bookingTicket.ticket.price)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div
      key={bookingTicket.ticket.id}
      className={clsx(
        `d-flex ribon-start align-items-center border-1 border-secondary bg-light p-5 justify-content-between flex-column flex-sm-row w-100 border-light-secondary pb-5  mb-3`,
        {
          'd-none':
            !isLoggedIn() &&
            bookingTicket.ticket.canBook.canBook === false &&
            bookingTicket.ticket.canBook.code !== 'ticketSoldOut',
        }
      )}
    >
      {/* Ticket Tickets */}
      <div className='d-flex me-5'>
        <div className='d-flex flex-column mb-3'>
          <div className='d-flex justify-content-center justify-content-lg-start align-items-center'>
            {isLoggedIn() && (
              <>
                {bookingTicket.ticket.isPrivate ? (
                  <KTIcon iconName='lock-3' iconType='solid' className='text-dark me-2 fs-3' />
                ) : (
                  <span className='symbol me-2'>
                    <span
                      title={bookingTicket.ticket.canBook.reason}
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      className={clsx('symbol-label symbol symbol-circle w-15px h-15px', {
                        'bg-success': bookingTicket.ticket.canBook.canBook === true,
                        'bg-danger': bookingTicket.ticket.canBook.canBook === false,
                      })}
                    ></span>
                  </span>
                )}
              </>
            )}

            {bookingTicket.ticket.canBook.code === 'byInvite' && (
              <KTIcon iconName='lock-3' iconType='solid' className='text-dark me-2 fs-3' />
            )}
            <span className='fw-bolder text-center text-sm-start text-dark fs-5'>
              {bookingTicket.ticket.name}{' '}
            </span>
            {bookingTicket.ticket.canBook.code === 'byInvite' && (
              <span className={`badge badge-info fw-bold ms-2`}>By Invite Only</span>
            )}

            {bookingTicket.ticket.canBook.code === 'ticketSoldOut' && (
              <span className={`text-danger fw-bold ms-2`}>
                {publicEvent.sellTickets ? 'Sold Out' : 'Fully Booked'}
              </span>
            )}
          </div>
          <span className='text-center text-sm-start fw-normal fs-6'>
            {showLeft && (
              <>
                <span className='text-center text-sm-start fw-normal fs-6'>
                  <KTIcon
                    iconType='outline'
                    iconName='information-4'
                    className='fs-6 me-1 text-dark'
                  />
                  {bookingTicket.ticket.qty - bookingTicket.ticket.bookedTickets} remaining
                  {'. '}
                </span>
              </>
            )}

            {bookingTicket.ticket.description}
          </span>
        </div>
      </div>

      {/* Toggler */}
      <div className='d-flex flex-column'>
        {bookingTicket.ticket.enableVariablePrice ? (
          <div className='d-flex'>
            <div className='input-group border-success border-3 border-solid'>
              <span className='input-group-text'>R</span>
              <input
                type='number'
                className='form-control mw-100px'
                value={bookingTicket.value}
                onChange={handleChange}
                min={bookingTicket.ticket.price}
                step={10}
              />
            </div>
          </div>
        ) : (
          <div className='d-flex'>
            <TicketToggler name={`bookingTickets[${index}].qty`} bookingTicket={bookingTicket} />
          </div>
        )}

        <div className='d-flex justify-content-center align-items-center'>
          {publicEvent.sellTickets && bookingTicket.ticket.price > 0 && (
            <span className='d-flex flex-column align-items-center me-3 mt-2 position-relative'>
              <span className='fw-normal fs-7 ms-2 fw-normal text-muted'>
                {bookingTicket.ticket.enableVariablePrice
                  ? bookingTicket.ticket.price > 0
                    ? `min ${formatMoney(bookingTicket.ticket.price, publicEvent.account.currency)}`
                    : ''
                  : `${formatMoney(bookingTicket.ticket.price, publicEvent.account.currency)} ea.`}
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export {BookingTicketItem}
