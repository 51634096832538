import {useEffect, useState} from 'react'
import {ErrorMessage, Form, Field, Formik, FormikValues} from 'formik'
import clsx from 'clsx'

import SubmitButton from '../../../../_components/SubmitButton'
import {useEventsStore} from '../core/eventsStore'
import NumberInput from '../../../../_components/NumberInput'
import {FormsQuery, initialFormsQuery, useFormStore} from '../../../pages/forms/core/formStore'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const BookingsSettings = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent} = useEventsStore()
  const {forms, getForms} = useFormStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()

  useEffect(() => {
    if (selectedAccountsUsers.account.id) {
      const payload: FormsQuery = {
        ...initialFormsQuery,
        account: selectedAccountsUsers.account.id,
      }

      getForms(payload)
    }

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Account successfully updated')

        // emit
        if (props.onSave) props.onSave(values)

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header p-5 p-md-10 border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_bookings_settings'
        aria-expanded='true'
        aria-controls='kt_bookings_settings'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step}. Bookings Experience</h3>
        </div>
      </div>

      <div id='kt_bookings_settings' className='show'>
        <Formik initialValues={currentEvent} onSubmit={handleSubmit} enableReinitialize>
          {(form) => {
            return (
              <Form>
                <>
                  {/* START::  Account Details */}
                  <div className='card-body border-top p-5 p-md-10'>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                            <Field
                              className='form-check-input me-3'
                              type='checkbox'
                              name='issueTickets'
                            />

                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder fs-5 text-dark mb-0'>
                                Issue Smart Tickets
                              </span>
                              <span className='text-muted fs-6'>
                                Issue scannable QR Code tickets when patrons successfully book for
                                tickets
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Automatically approve bookings */}
                    {form.values.sellTickets !== true && (
                      <div className='fv-row mb-10'>
                        <div className='row'>
                          <div className='col'>
                            <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input me-3'
                                type='checkbox'
                                name='autoApprove'
                              />

                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span className='fw-bolder fs-5 text-dark mb-0'>
                                  Automatically Approve Bookings
                                </span>
                                <span className='text-muted fs-6'>
                                  If checked new bookings will be automatically approved. Else, they
                                  will be set to pending and require manual approval.
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Abandon Bookings */}
                    {form.values.sellTickets && (
                      <div className='fv-row mb-10'>
                        <div className='row'>
                          <div className='col'>
                            <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input me-3'
                                type='checkbox'
                                name='abandonedEnabled'
                              />

                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span className='fw-bolder fs-5 text-dark mb-0'>
                                  Automatically Set Incomplete Bookings to Abandoned
                                </span>
                                <span className='text-muted fs-6'>
                                  If checked unpaid bookings will be automatically set to abandoned
                                  after a period
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {form.values.abandonedEnabled && form.values.sellTickets && (
                      <div className='fv-row mb-10'>
                        <div className='row'>
                          <div className='col d-flex flex-md-row flex-column'>
                            <div className='d-flex flex-column flex-grow-1 ms-md-12 me-md-3'>
                              <label className='form-label fw-bolder'>
                                Abandonment Delay (in minutes)
                              </label>
                              <span className='text-muted fs-6'>
                                Enter the period after which unpaid bookings will be set to
                                abandoned
                              </span>
                              <ErrorMessage
                                name='abandonedDelay'
                                component='div'
                                className='text-danger'
                              />
                            </div>
                            <div className='d-flex w-200px'>
                              <NumberInput
                                name='abandonedDelay'
                                min={0}
                                max={60}
                                placeholder='Enter period'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* CUSTOM FORM */}

                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                            <Field
                              className='form-check-input me-3'
                              type='checkbox'
                              name='enablePatronForm'
                            />

                            <span className='form-check-label d-flex flex-column align-items-start'>
                              <span className='fw-bolder fs-5 text-dark mb-0'>
                                Enable Custom Patron Form
                              </span>
                              <span className='text-muted fs-6'>
                                If checked patrons will be required to fill a custom form to
                                activate their tickets
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {form.values.enablePatronForm && (
                      <div className='fv-row mb-10 ms-md-12 me-md-3'>
                        <div className='row'>
                          <div className='col'>
                            <label className='form-label fw-bolder'>Select Custom Form</label>

                            <Field
                              as='select'
                              name='patronForm'
                              className='form-select form-select-solid'
                            >
                              <option value=''>Select a form</option>
                              {forms
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((form) => (
                                  <option key={form.id} value={form.id}>
                                    {form.name}
                                  </option>
                                ))}
                            </Field>

                            <ErrorMessage
                              name='customFormTitle'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='card-footer d-flex justify-content-end p-5 p-md-10'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                  {/* END:: Account Details  */}
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {BookingsSettings}
