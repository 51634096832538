import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {
  initialPatronPagination,
  PatronQueryModel,
  PatronsPagination,
  usePatronStore,
} from '../../../modules/patrons/core/patronStore'
import {TicketModel, useEventsStore} from '../../../modules/events/core/eventsStore'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {Typeahead} from 'react-bootstrap-typeahead'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {initialBookingsPagination} from '../../../modules/bookings/core/bookingsStore'
import QueryPartBadge from '../../audience/partials/QueryPartBadge'
import {MessageModel, RecipientValueModel} from '../core/messagesStore'
import {useFormikContext} from 'formik'
import {Link} from 'react-router-dom'

type Props = {
  onSave: (recipients: RecipientValueModel) => void
  defaultRecipients?: RecipientValueModel
  disabled?: boolean
}

const MessageEditorRecipients: FC<Props> = ({disabled, defaultRecipients, onSave}) => {
  const [recipients, setRecipients] = useState<RecipientValueModel>(
    defaultRecipients || {
      queryType: 'bookingQuery',
      query: {},
      number: 0,
    }
  )
  const [show, setShow] = useState(false)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const {getPatrons, getPatronQueries, getPatronsFromBookings, patrons, pagination, patronQueries} =
    usePatronStore()
  const [preview, setPreview] = useState(false)
  const {events, getEvents, setQuery} = useEventsStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [selectedTickets, setSelectedTickets] = useState<TicketModel[]>()
  const [selectedStatus, setSelectedStatus] = useState<string>('all')
  const [selectedPatronQuery, setSelectedPatronQuery] = useState<PatronQueryModel>()
  const [canPreview, setCanPreview] = useState<boolean>(false)
  const {values} = useFormikContext()

  const v = values as MessageModel

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    // setup event query
    setQuery({
      scope: 'all',
      limit: 9999999,
      sortBy: 'name:asc',
    })

    // get events
    getEvents(selectedAccountsUsers.account.id)
    getPatronQueries({account: selectedAccountsUsers.account.id})

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account])

  useEffect(() => {
    if (recipients.queryType === 'bookingQuery') {
      if (selectedTickets && selectedTickets.length > 0) {
        setCanPreview(true)
      } else {
        setCanPreview(false)
      }
    } else if (recipients.queryType === 'patronQuery') {
      if (selectedPatronQuery) {
        setCanPreview(true)
      } else {
        setCanPreview(false)
      }
    }

    // eslint-disable-next-line
  }, [selectedTickets, selectedPatronQuery])

  useEffect(() => {
    setRecipients({...recipients, number: pagination.totalResults})
    // eslint-disable-next-line
  }, [pagination])

  useEffect(() => {
    if (defaultRecipients && events.length > 0) {
      if (defaultRecipients.queryType === 'bookingQuery') {
        setSelectedTickets(
          defaultRecipients.query.tickets.split(',').map((id) => {
            const tickets = events
              ?.flatMap((event) => event.tickets)
              .find((ticket) => ticket.id === id)
            return tickets
          })
        )
        setSelectedStatus(defaultRecipients.query.status || 'all')
      } else if (
        defaultRecipients.queryType === 'patronQuery' &&
        patronQueries &&
        patronQueries.length > 0
      ) {
        const p = patronQueries.find((q) => q.id === defaultRecipients.query.patronQuery)
        setSelectedPatronQuery(p)
      }
    }

    // eslint-disable-next-line
  }, [defaultRecipients, events, patronQueries])

  const handleChangeEvent = (selected: any[]) => {
    setSelectedTickets(selected as TicketModel[])
  }

  const handleSaveRecipients = () => {
    onSave(recipients)
    setShow(false)
  }

  const handlePreview = async () => {
    setPreview(true)
    setLoadingPreview(true)

    // get recipients
    if (recipients.queryType === 'bookingQuery') {
      // setup payload
      const queryPayload = {
        ...initialBookingsPagination,
        tickets: selectedTickets?.map((ticket) => ticket.id).join(','),
      }
      // conditionally set status
      if (selectedStatus !== 'all') {
        queryPayload.status = selectedStatus
      }

      // setRecipients({...recipients, query: payload})
      setRecipients({...recipients, query: queryPayload})

      // get recipients
      getPatronsFromBookings({...queryPayload, limit: 5}).finally(() => setLoadingPreview(false))
    } else if (recipients.queryType === 'patronQuery') {
      if (!selectedPatronQuery) return
      // get patrons from query
      const queryPayload: PatronsPagination = {
        ...initialPatronPagination,
        account: selectedAccountsUsers.account.id,
        patronQuery: selectedPatronQuery.id,
        limit: 5,
      }

      // set recipients
      setRecipients({...recipients, query: queryPayload})

      // get patrons
      getPatrons(queryPayload)
        .then()
        .finally(() => setLoadingPreview(false))
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end'>
        {v.recipients.number > 0 && (
          <button
            type='button'
            onClick={() => {
              setShow(true)
              handlePreview()
            }}
            className='btn btn-icon btn-active-dark me-2'
          >
            <KTIcon iconName='eye' iconType='outline' className='fs-3' />
          </button>
        )}
        <button
          disabled={disabled}
          type='button'
          onClick={() => setShow(true)}
          className='btn btn-outline btn-active-dark'
        >
          Edit Recipients
        </button>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className='modal-dialog-centered modal-dialog-scrollable'
      >
        <Modal.Header>
          <h5 className='modal-title'>Edit Recipients</h5>
        </Modal.Header>
        <Modal.Body>
          {/* SELECT RECIPIENTS */}
          {!preview && (
            <div className='d-flex flex-column w-100'>
              {/* PILLS TO SWITCH QUERY TYPE */}
              <div className='d-flex align-items-center justify-content-between mb-5'>
                <label className='form-label fw-bold fs-6'>Get Recipients From...</label>
                <ul
                  className='nav nav-pills nav-pills-secondary p-1 bg-light border border-secondary rounded mb-3'
                  id='pills-tab'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <button
                      className={clsx('btn btn-sm btn-active-dark', {
                        active: recipients.queryType === 'bookingQuery',
                      })}
                      id='pills-profile-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'
                      onClick={() => setRecipients({...recipients, queryType: 'bookingQuery'})}
                    >
                      Bookings
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className={clsx('btn btn-sm btn-active-dark', {
                        active: recipients.queryType === 'patronQuery',
                      })}
                      id='pills-contact-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-contact'
                      type='button'
                      role='tab'
                      aria-controls='pills-contact'
                      aria-selected='false'
                      onClick={() => setRecipients({...recipients, queryType: 'patronQuery'})}
                    >
                      Circles
                    </button>
                  </li>
                </ul>
              </div>

              <div className='d-flex align-items-center fs-5 fw-bold w-100 mb-10'>
                <span className='me-5'>Where</span>
                <div className='separator flex-grow-1' />
              </div>

              <div className='tab-content w-100' id='pills-tabContent'>
                {/* BOOKINGS PAGE */}
                <div
                  className={clsx('tab-pane fade show', {
                    'show active': recipients.queryType === 'bookingQuery',
                  })}
                  id='pills-profile'
                  role='tabpanel'
                  aria-labelledby='pills-profile-tab'
                >
                  {/* event */}
                  <div className='d-flex flex-column mb-5'>
                    <label className='form-label fw-bold fs-6'>Tickets</label>
                    <Typeahead
                      id='patron-tags-menu'
                      multiple
                      selected={selectedTickets}
                      onChange={handleChangeEvent}
                      labelKey={(option: any) => {
                        return `${
                          events?.find((e) => {
                            return e.id === option.event
                          })!.name
                        } / ${option.name}`
                      }}
                      options={events?.flatMap((event) => event.tickets)}
                    />
                  </div>
                  {selectedTickets && selectedTickets.length > 0 && (
                    <div className='d-flex flex-column mb-5'>
                      <label className='form-label fw-bold fs-6'>Booking Status</label>
                      <select
                        name='status'
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.currentTarget.value)}
                        className='form-select'
                      >
                        <option value='all'>All</option>
                        <option value='complete'>Complete</option>
                        <option value='complete'>Checked</option>
                        <option value='pending'>Pending</option>
                        <option value='approved'>Cancelled</option>
                        <option value='rejected'>Abandonded</option>
                        <option value='rejected'>Rejected</option>
                      </select>
                    </div>
                  )}
                  <div style={{marginBottom: '100px'}}></div>
                </div>

                {/* PATRON PANE */}
                <div
                  className={clsx('tab-pane fade', {
                    'show active': recipients.queryType === 'patronQuery',
                  })}
                  id='pills-contact'
                  role='tabpanel'
                  aria-labelledby='pills-contact-tab'
                >
                  <div className={'v-scrollable border border-light'} style={{maxHeight: '250px'}}>
                    {patronQueries.map((query) => (
                      <div
                        key={query.id}
                        onClick={() => {
                          // toggle
                          if (selectedPatronQuery && selectedPatronQuery.id === query.id) {
                            setSelectedPatronQuery(undefined)
                          } else {
                            setSelectedPatronQuery(query)
                          }
                        }}
                        className={clsx(
                          'd-flex flex-column mb-2 rounded border border-secondary bg-hover-light p-3',
                          {
                            'bg-light': selectedPatronQuery && selectedPatronQuery.id === query.id,
                          }
                        )}
                      >
                        <div className='d-flex align-items-center'>
                          {selectedPatronQuery && selectedPatronQuery.id === query.id ? (
                            <KTIcon
                              iconName='check-circle'
                              iconType='solid'
                              className='text-dark fs-1 me-5'
                            />
                          ) : (
                            <div
                              className='w-20px h-20px me-5 border border-2 border-secondary'
                              style={{borderRadius: '50%'}}
                            ></div>
                          )}

                          <div className='d-flex flex-column'>
                            <div className='d-flex'>
                              <span className='fw-bold fs-5'>{query.name}</span>
                            </div>
                            <div className='text-muted fs-7 d-flex me-1'>
                              {query.filters.map((qp) => (
                                <QueryPartBadge
                                  events={events}
                                  key={qp.field}
                                  queryPart={qp}
                                  mode='text'
                                  showConnector={
                                    query.filters.indexOf(qp) < query.filters.length - 1
                                      ? true
                                      : false
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* NO PATRONS QUERIES */}
                {patronQueries.length === 0 && recipients.queryType === 'patronQuery' && (
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-column align-items-center  fs-5 fw-bold w-100 my-7 p-10 border border-dashed rounded bg-light'>
                      <span>Oh no, your audience is not yet organised into circles.</span>
                      <span className='fs-7 text-muted'>
                        Create circles by going to the audience page and clicking on the filter
                        button. Use the button below to get started.
                      </span>
                      <Link
                        to='/audience'
                        className='btn btn-sm btn-info btn-active-secondary mt-5'
                      >
                        Go to Audience Page
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* PREVIEW RECIPIENTS */}
          {preview && (
            <>
              {/* PREVIEW LOADING */}
              {loadingPreview && (
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-column  fs-5 fw-bold w-100 mb-5'>
                    <span>Fetching Recipients...</span>
                    <span className='fs-7 text-muted'>Please wait</span>
                  </div>
                </div>
              )}

              {/* IF THERE'S A PREVIEW */}
              {!loadingPreview && patrons.length > 0 && (
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-column  fs-5 fw-bold w-100 mb-5'>
                    <span>Found {pagination.totalResults} Recipients</span>
                    <span className='fs-7 text-muted'>See preview below</span>
                  </div>

                  {/* PREVIEW PATRONS TABLE */}

                  <div
                    className='table-responsive bg-light p-5 rounded border border-secondary'
                    style={{
                      inset: '0px',
                      overflowX: 'auto',
                      overflowY: 'auto',
                      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800 text-start text-uppercase gs-0'>
                          <th className='min-w-200px'>Name</th>
                          <th className='min-w-150px'>Email</th>
                          <th className='min-w-150px'>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patrons.map((patron) => (
                          <tr key={patron.id}>
                            <td>{patron.name}</td>
                            <td>
                              <a href={`mailto:${patron.email}`}>{patron.email}</a>
                            </td>
                            <td>{patron.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* IF THERE'S NO PREVIEW  */}
              {!loadingPreview && patrons.length === 0 && (
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-column align-items-center  fs-5 fw-bold w-100 my-5 p-10 border border-dashed rounded bg-light'>
                    <span>Hmm...found {pagination.totalResults} recipients</span>
                    <span className='fs-7 text-muted'>Please try another set of criteria</span>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          className={clsx('d-flex', {
            'justify-content-end': !preview,
            'justify-content-between': preview,
          })}
        >
          {preview && (
            <button
              className='btn btn-sm btn-outline btn-active-dark'
              onClick={() => setPreview(false)}
            >
              <KTIcon iconName='left' iconType='outline' /> back
            </button>
          )}

          {preview ? (
            <button
              className='btn btn-outline btn-sm btn-active-dark'
              onClick={handleSaveRecipients}
            >
              Save Recipients
            </button>
          ) : (
            <>
              <button className='btn btn-sm btn-active-dark me-2' onClick={() => setShow(false)}>
                Close
              </button>

              <button
                disabled={!canPreview}
                className='btn btn-outline btn-sm btn-active-dark'
                onClick={handlePreview}
              >
                Preview Recipients
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MessageEditorRecipients
