import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _cloneEvent,
  _deleteCheckpoint,
  _deleteEvent,
  _deleteTicket,
  _getCheckpoints,
  _getEvent,
  _getEventByHandle,
  _getEventDashboard,
  _getEvents,
  _getPublicEvent,
  _getTickets,
  _handleExists,
  _patchCheckpoint,
  _patchCheckpointSettings,
  _patchEvent,
  _patchEventImage,
  _patchTicket,
  _patchTickets,
  _postCheckpoint,
  _postCheckpointSettings,
  _postEvent,
  _postTicket,
  _searchEvents,
} from './eventsApi'
import {formatMoney, getDirtyValues, omit, pick} from '../../../../_helpers/_helpers'
import {
  accountInitialValues,
  AccountModel,
  accountsUsersStore,
} from '../../settings/core/accountsUsersStore'
import {
  initialLineOrBarChartData,
  LineOrBarChartDataModel,
} from '../../../pages/dashboard/core/dashboardStore'

export type CheckpointModel = {
  name: string
  event: string
  color: string
  id?: string
}

export type CheckpointSettingsModel = {
  checkpoint: CheckpointModel
  qty: number
  id: string
}

export type BookingPieItemModel = {
  id: number | null
  name?: string
  value?: number
  count?: number
}

export type PieChartDataModel = {
  labels: string[]
  series: number[]
  legend: string[]
}

export type FunnelValueModel = {
  value: number
  abandoned: number
  converted: number
  relativeAbandoned: number
  relativeConverted: number
  abandonedRate: number
  conversionRate: number
  relativeAbandonedRate: number
  relativeConvertedRate: number
}

export type funnelDataModel = {
  labels: string[]
  values: [FunnelValueModel]
}

export type EventDashboardModel = {
  bookingsPieData: PieChartDataModel
  bookingsChartData: {
    current: LineOrBarChartDataModel
    previous: LineOrBarChartDataModel
  }
  salesChartData?: {
    current: LineOrBarChartDataModel
    previous: LineOrBarChartDataModel
  }
  funnelData?: {
    current: funnelDataModel
    previous: funnelDataModel
  }
}

const initialFunnelData: funnelDataModel = {
  labels: [],
  values: [
    {
      value: 0,
      abandoned: 0,
      converted: 0,
      relativeAbandoned: 0,
      relativeConverted: 0,
      abandonedRate: 0,
      conversionRate: 0,
      relativeAbandonedRate: 0,
      relativeConvertedRate: 0,
    },
  ],
}

export const initialEventDashboard: EventDashboardModel = {
  bookingsPieData: {labels: [], series: [], legend: []},
  bookingsChartData: {
    current: {...initialLineOrBarChartData},
    previous: {...initialLineOrBarChartData},
  },
  funnelData: {
    current: {...initialFunnelData},
    previous: {...initialFunnelData},
  },
}

export const initialCheckpoint: CheckpointModel = {
  name: '',
  event: '',
  color: '',
}

export type CheckpointsQuery = {
  limit: number
  page: number
}

export type TicketModel = {
  id?: string
  name: string
  description: string
  price: number
  qty: number
  increment: number
  startDate: Date
  endDate: Date
  minQty: number
  maxQty: number
  checkpoints?: CheckpointModel[]
  checkpointSettings: CheckpointSettingsModel[]
  archived: boolean
  event: string
  forceMinQty: boolean
  enableVariablePrice: boolean
  isPrivate: boolean
  bookedTickets: number
  order: number
  canBook: {
    canBook: boolean
    reason: string
    code: string
  }
}

export const initialTicket: TicketModel = {
  id: '',
  name: '',
  description: '',
  price: 0,
  qty: 1,
  increment: 1,
  startDate: new Date(),
  endDate: new Date(),
  minQty: 1,
  maxQty: 10,
  checkpointSettings: [],
  archived: false,
  event: '',
  forceMinQty: false,
  enableVariablePrice: false,
  isPrivate: false,
  bookedTickets: 0,
  order: 0,
  canBook: {
    canBook: false,
    reason: '',
    code: '',
  },
}

export type EventStatsModel = {
  bookingsCount: number
  bookingsValue: number
  bookingsTicketsCount: number
  transactionsCount: number
  transactionsAmount: number
  ticketsTotalQty: any
  ticketsTotalValue: any
  minTicketPrice: number
  maxTicketPrice: number

  eventViews: number
  bookingViews: number
  bookingConfirmed: number
  bookingPaymentComplete: number
  conversionRate: number
  bookingConversionRate: number
}

export const initialEventsStats: EventStatsModel = {
  bookingsCount: 0,
  bookingsValue: 0,
  bookingsTicketsCount: 0,
  transactionsCount: 0,
  transactionsAmount: 0,
  ticketsTotalQty: 0,
  ticketsTotalValue: 0,
  minTicketPrice: 0,
  maxTicketPrice: 0,

  eventViews: 0,
  bookingViews: 0,
  bookingConfirmed: 0,
  bookingPaymentComplete: 0,
  conversionRate: 0,
  bookingConversionRate: 0,
}

export type PublicEventModel = {
  account: AccountModel
  id: string
  name: string
  handle: string
  startDate: string
  endDate: string
  location: string
  excerpt: string
  content: string
  logo: string
  artwork: string
  banner: string
  buttonLabel: string
  organiserName: string
  organiserEmail: string
  organiserPhone: string
  organiserWebsite: string
  organiserX: string
  organiserIG: string
  organiserFB: string
  organiserWhatsapp: string
  organiserYoutube: string
  sellTickets: boolean
  tickets: TicketModel[]
  stats: EventStatsModel
  canBook: {
    canBook: boolean
    reason: string
    code: string
  }
  createCreated?: string
  dateUpdated?: string

  // FORMS
  enablePatronForm: boolean
  patronForm?: string
}

export type EventModel = {
  id: string | null
  account: string
  billingModel: string
  commission: number
  sellTickets: boolean
  name: string
  handle: string
  startDate: string
  endDate: string
  location: string
  issueTickets: boolean
  autoApprove: boolean
  status: string
  visibility: string
  currency: string
  ticketsOpeningDate: string
  ticketsClosingDate: string
  excerpt: string
  content: string
  logo: string
  artwork: string
  banner: string
  buttonLabel: string
  organiserName: string
  organiserEmail: string
  organiserPhone: string
  organiserWebsite: string
  organiserX: string
  organiserIG: string
  organiserFB: string
  organiserWhatsapp: string
  organiserYoutube: string

  // FORMS
  enablePatronForm: boolean
  patronForm?: string

  // notifications
  pendingEnabled: boolean
  pendingEmailSubject: string
  pendingEmail: string
  pendingNotification: string

  completedEnabled: boolean
  completedEmailSubject: string
  completedEmail: string
  completedNotification: string

  cancelledEnabled: boolean
  cancelledEmailSubject: string
  cancelledEmail: string
  cancelledNotification: string

  beforeEnabled: boolean
  beforeEmailSubject: string
  beforeEmail: string
  beforeNotification: string
  beforeDays: number

  afterEnabled: boolean
  afterEmailSubject: string
  afterEmail: string
  afterDays: number
  afterNotification: string

  abandonedEnabled: boolean
  abandonedEmailSubject: string
  abandonedEmail: string
  abandonedNotification: string
  abandonedDelay: number

  archived: boolean
  tickets: TicketModel[]
  allowInstallments: boolean
  maxInstallments: number
  installmentInterval: number
  minBookingAmount: number
  installmentCutOffDate: string
  allowRefunds: boolean
  refundBeforeDays: number
  refundFeePercentage: number
  allowAffiliates: boolean
  affiliateCommission: number
  stats: EventStatsModel
  canBook: {
    canBook: boolean
    reason: string
    code: string
  }
  dateCreated?: string
  dateUpdated?: string
}

export type Pagination = {
  page: number
  limit: number
  totalPages: number
  totalResults: number
}

export const initialPagination: Pagination = {
  page: 1,
  limit: 10,
  totalPages: 1,
  totalResults: 0,
}

export const initialEvent: EventModel = {
  id: null,
  account: '',
  billingModel: 'self',
  sellTickets: true,
  commission: 0,
  name: '',
  handle: '',
  startDate: '',
  endDate: '',
  location: '',
  issueTickets: false,
  autoApprove: false,
  status: 'draft',
  visibility: 'public',
  currency: 'ZAR',
  ticketsOpeningDate: '',
  ticketsClosingDate: '',
  excerpt: '',
  content: '',
  logo: '',
  artwork: '',
  banner: '',
  buttonLabel: 'Book Now',
  organiserName: '',
  organiserEmail: '',
  organiserPhone: '',
  organiserWebsite: '',
  organiserX: '',
  organiserIG: '',
  organiserFB: '',
  organiserWhatsapp: '',
  organiserYoutube: '',

  pendingEnabled: true,
  pendingEmailSubject: 'Your booking is pending',
  pendingEmail:
    '<p>Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>,</p> <p>Your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is set to pending.</p> <p><span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span></p>',
  pendingNotification:
    'Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>, your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is set to pending. <span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span>',

  completedEnabled: true,
  completedEmailSubject: 'Your booking is completed',
  completedEmail:
    '<p>Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>,</p> <p>Your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is completed.  You can view your booking by following this link: <span className="mention" data-type="mention" data-id="bookingLink">{{bookingLink}}</span>.</p><p>If you want to share tickets with other recipients, you must first login as a patron.  To login as a patron, visit <a href="https://skybookings.com">skybookings.com</a> and click retrieve tickets below the search bar.  Then follow the instructions to login and open your booking, at which point you will see an option to share tickets.</p><p>Enjoy <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span>.</p><p><span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span></p>',

  completedNotification:
    'Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>, your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is set to completed <span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span>.  Use the following link to retrieve your tickets:  <span className="mention" data-type="mention" data-id="bookingLink">{{bookingLink}}</span>',

  cancelledEnabled: false,
  cancelledEmailSubject: 'Your booking is cancelled',
  cancelledEmail:
    '<p>Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>,</p> <p>Your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is set to cancelled</p> <p><span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span></p>',
  cancelledNotification:
    'Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>, your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is set to cancelled. <span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span>',

  beforeEnabled: true,
  beforeNotification:
    'Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>.  This is a reminder that your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is in <span className="mention" data-type="mention" data-id="daysUntilEvent">{{daysUntilEvent}}</span> days. <span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span>',

  beforeEmailSubject:
    'Your booking is in <span className="mention" data-type="mention" data-id="daysUntilEvent">{{daysUntilEvent}}</span> days',
  beforeEmail:
    '<p>Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>.</p> <p>This is a reminder that your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> is in <span className="mention" data-type="mention" data-id="daysUntilEvent">{{daysUntilEvent}}</span> days.</p> <p><span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span></p>',
  beforeDays: 1,

  afterEnabled: true,
  afterNotification:
    'Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>.  Thank you for attending <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span>. <span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span>',
  afterEmailSubject:
    'Thank you for attending <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span>',
  afterEmail:
    '<p>Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>.</p> <p>Thank you for attending <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span>.</p> <p><span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span></p>',
  afterDays: 1,

  abandonedEnabled: false,
  abandonedNotification:
    'Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>.  We noticed you started booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> but did not complete the process.  If you need any help, please contact us. <span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span>',
  abandonedEmailSubject:
    'Your booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> will be cancelled soon',
  abandonedEmail:
    '<p>Hi <span className="mention" data-type="mention" data-id="bookingName">{{bookingName}}</span>.</p> <p>We noticed you started booking for <span className="mention" data-type="mention" data-id="eventName">{{eventName}}</span> but did not complete the process.  If you need any help, please contact us.</p> <p><span className="mention" data-type="mention" data-id="organiserName">{{organiserName}}</span></p>',
  abandonedDelay: 15,

  archived: false,
  tickets: [],

  allowInstallments: false,
  maxInstallments: 60,
  installmentInterval: 30,
  minBookingAmount: 2500,
  installmentCutOffDate: '',

  // forms
  enablePatronForm: false,

  // refunds
  allowRefunds: false,
  refundBeforeDays: 3,
  refundFeePercentage: 10,

  // affiliates
  allowAffiliates: false,
  affiliateCommission: 10,

  // stats
  stats: initialEventsStats,

  // can book
  canBook: {
    canBook: false,
    reason: '',
    code: '',
  },
}

export const initialPublicEvent: PublicEventModel = {
  account: {
    ...accountInitialValues,
  },
  id: '',
  name: '',
  handle: '',
  startDate: '',
  endDate: '',
  location: '',
  excerpt: '',
  content: '',
  logo: '',
  artwork: '',
  banner: '',
  buttonLabel: '',
  organiserName: '',
  organiserEmail: '',
  organiserPhone: '',
  organiserWebsite: '',
  organiserX: '',
  organiserIG: '',
  organiserFB: '',
  organiserWhatsapp: '',
  organiserYoutube: '',
  sellTickets: false,
  tickets: [],
  stats: initialEventsStats,
  canBook: {
    canBook: false,
    reason: '',
    code: '',
  },

  // forms
  enablePatronForm: false,
  patronForm: '',
}

export type EventsQuery = {
  sortBy: string
  limit: number
  visibility?: string
  page: number
  scope: 'all' | 'future' | 'past'
  archived: boolean
}

export const initialEventsQuery: EventsQuery = {
  sortBy: 'createdAt',
  limit: 10,
  page: 1,
  scope: 'future',
  archived: false,
}

type EventsStore = {
  currentEvent: EventModel
  events: EventModel[]
  pagination: Pagination
  query: EventsQuery
  bookingsPieData: PieChartDataModel
  bookingsChartData: {current: LineOrBarChartDataModel; previous: LineOrBarChartDataModel}
  salesChartData: {current: LineOrBarChartDataModel; previous: LineOrBarChartDataModel}
  funnelData: {current: funnelDataModel; previous: funnelDataModel}

  // events
  setQuery: (query: Partial<EventsQuery>) => void
  saveEvent: (event: Partial<EventModel>) => Promise<void>
  saveEventImage: (fieldName: string, image: string) => Promise<void>
  handleExists: (handle: string) => Promise<boolean>
  getEvents: (accountId: string) => Promise<EventModel[]>
  getMoreEvents: (accountId: string) => Promise<EventModel[]>
  getEvent: (id: string) => Promise<EventModel>
  getEventByHandle: (id: string) => Promise<EventModel>
  setCurrentEvent: (id: string, isPublic: boolean) => Promise<void>
  unsetCurrentEvent: () => void
  deleteEvent: (id: string) => Promise<void>
  getEventDashboard: (
    id: string,
    startDate: string,
    endDate: string
  ) => Promise<EventDashboardModel>
  searchEvents: (query: string) => Promise<EventModel[]>
  convertEventToPublic: (account: AccountModel, event: EventModel) => PublicEventModel
  cloneEvent: (eventId: string) => Promise<EventModel>

  // public event
  publicEvent: PublicEventModel
  getPublicEvent: (id: string) => Promise<PublicEventModel>

  // checkpoint functions
  checkpoints: CheckpointModel[]
  saveCheckpoint: (eventId: string, checkpoint: Partial<CheckpointModel>) => Promise<void>
  getCheckpoints: (eventId: string, query: CheckpointsQuery) => Promise<void>
  deleteCheckpoint: (eventId: string, id: string) => Promise<void>

  // saveTicket
  tickets: TicketModel[]
  ticketsPagination: Pagination
  saveTicket: (eventId: string, ticket: TicketModel) => Promise<void>
  saveTickets: (eventId: string, tickets: TicketModel[]) => Promise<void>
  getTickets: (eventId: string, query: any) => Promise<TicketModel[]>
  deleteTicket: (eventId: string, id: string) => Promise<void>
}

const createStore: StateCreator<EventsStore> = (set, get) => ({
  currentEvent: {...initialEvent},
  events: [],
  checkpoints: [],
  query: {...initialEventsQuery},
  pagination: {...initialPagination},
  ticketsPagination: {...initialPagination},
  bookingsPieData: {labels: [], series: [], legend: []},
  bookingsChartData: {
    current: {...initialLineOrBarChartData},
    previous: {...initialLineOrBarChartData},
  },
  salesChartData: {
    current: {...initialLineOrBarChartData},
    previous: {...initialLineOrBarChartData},
  },
  funnelData: {current: {...initialFunnelData}, previous: {...initialFunnelData}},

  publicEvent: {
    ...initialPublicEvent,
  },

  setQuery: (query: Partial<EventsQuery>) => {
    set({query: {...get().query, ...query}})
  },

  saveEvent: async (event: Partial<EventModel>) => {
    // get dirty values
    const {currentEvent} = get()

    // if event i snew post it,
    let response: any = {}

    if (!currentEvent.id) {
      const values = omit(event, [
        'id',
        'canBook',
        'stats',
        'tickets',
        'ticketsOpeningDate',
        'ticketsClosingDate',
        'excerpt',
        'content',
        'logo',
        'artwork',
        'banner',
        'allowAffiliates',
        'affiliateCommission',
        'installmentCutOffDate',
        'organiserName',
        'organiserEmail',
        'account',
      ])

      // if admin is using another account
      const {selectedAccountsUsers} = accountsUsersStore.getState()
      if (selectedAccountsUsers) values.account = selectedAccountsUsers.account.id

      response = await _postEvent(values)
      const newEvent = {
        ...initialEvent,
        ...response.data,
        artwork: response.data.artwork ? response.data.artwork : 'noimage',
      }

      // add event to the events list
      const {events} = get()
      const newEvents = [newEvent, ...events]
      set({events: newEvents})

      return newEvent
    } else {
      const dirtyValues = getDirtyValues(event, currentEvent)
      const values = omit(dirtyValues, ['id', 'canBook', 'stats', 'tickets'])
      response = await _patchEvent(currentEvent.id, values)
      const updatedEvent = {
        ...initialEvent,
        ...response.data,
        artwork: response.data.artwork ? response.data.artwork : 'noimage',
      }

      // update event based on id in response

      const {events} = get()
      const newEvents = events.map((event) => {
        if (event.id === response.data.id) {
          return updatedEvent
        }
        return event
      })
      set({events: newEvents})

      // update current event
      set({currentEvent: updatedEvent})

      return updatedEvent
    }
  },

  saveEventImage: async (fieldName: string, image: string | null) => {
    // save event image
    const {currentEvent} = get()
    const response = await _patchEventImage(currentEvent.id, fieldName, image)

    // update the current event
    set({currentEvent: {...currentEvent, ...response.data}})
  },

  getEvents: async (accountId: string) => {
    // get events
    const {query} = get()
    const response = await _getEvents({...query, account: accountId})
    const pagination: Pagination = {
      page: response.data.page,
      limit: response.data.limit,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }

    // start each result with initial Values (to avoid uncontrolled inputs error)
    const events = response.data.results.map((event: EventModel) => {
      return {...initialEvent, ...event, artwork: event.artwork ? event.artwork : 'noimage'}
    })

    set({events, pagination})

    return events
  },

  searchEvents: async (query: string) => {
    // get events
    const response = await _searchEvents({query})

    // start each result with initial Values (to avoid uncontrolled inputs error)
    const events = response.data.map((event: EventModel) => {
      return {...initialEvent, ...event, artwork: event.artwork ? event.artwork : 'noimage'}
    })

    set({events})

    return events
  },

  convertEventToPublic: (account: AccountModel, event: EventModel) => {
    // convert event to PublicEventModel
    const publicEvent: PublicEventModel = {
      account,
      id: event.id!,
      name: event.name,
      handle: event.handle,
      startDate: event.startDate,
      endDate: event.endDate,
      location: event.location,
      excerpt: event.excerpt,
      content: event.content,
      logo: event.logo,
      artwork: event.artwork,
      banner: event.banner,
      buttonLabel: event.buttonLabel,
      organiserName: event.organiserName,
      organiserEmail: event.organiserEmail,
      organiserPhone: event.organiserPhone,
      organiserWebsite: event.organiserWebsite,
      organiserX: event.organiserX,
      organiserIG: event.organiserIG,
      organiserFB: event.organiserFB,
      organiserWhatsapp: event.organiserWhatsapp,
      organiserYoutube: event.organiserYoutube,
      sellTickets: event.sellTickets,
      tickets: event.tickets,
      stats: event.stats,
      canBook: event.canBook,

      // FORMS
      enablePatronForm: event.enablePatronForm,
      patronForm: event.patronForm,
    }

    return publicEvent
  },

  getMoreEvents: async () => {
    // update query
    const {query, pagination} = get()
    const newQuery = {...query, page: pagination.page + 1}
    set({query: newQuery})

    // get events and append them to the events list
    const response = await _getEvents(newQuery)

    const newEvents = response.data.results.map((event: EventModel) => {
      return {...initialEvent, ...event, artwork: event.artwork ? event.artwork : 'noimage'}
    })

    const allEvents = [...get().events, ...newEvents]
    set({events: allEvents})

    // update pagination
    const newPagination: Pagination = {
      ...pagination,
      page: response.data.page,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }
    set({pagination: newPagination})

    return allEvents
  },

  getEvent: async (id: string) => {
    // get event
    const response = await _getEvent(id)

    // new eventp
    const newEvent = {
      ...initialEvent,
      ...response.data,
      artwork: response.data.artwork ? response.data.artwork : 'noimage',
    }

    set({currentEvent: newEvent})

    return newEvent
  },

  getEventByHandle: async (handle: string) => {
    // get event
    const response = await _getEventByHandle(handle)

    // new event
    const newEvent = {
      ...initialEvent,
      ...response.data,
      artwork: response.data.artwork ? response.data.artwork : 'noimage',
    }

    set({currentEvent: newEvent})

    return response.data
  },

  getEventDashboard: async (id: string, startDate: string, endDate: string) => {
    try {
      // get event
      const response = await _getEventDashboard(id, {startDate, endDate})

      // PREPARE PIE CHART
      const {account} = accountsUsersStore.getState().selectedAccountsUsers
      const labels = response.data.bookingsPie.map((item: BookingPieItemModel) => {
        return item.name ? item.name : 'Others'
      })
      const series = response.data.bookingsPie.map((item: BookingPieItemModel) => {
        return item.value ? item.value : item.count
      })
      const legend = response.data.bookingsPie.map((item: BookingPieItemModel) => {
        return item.value
          ? (item.name !== undefined ? item.name : 'Other') +
              ' ' +
              formatMoney(item.value, account.currency, 0)
          : item.name + ' ' + item.count
      })

      // PREPARE DASHBOARD
      const dashboard: EventDashboardModel = {
        bookingsPieData: {labels, series, legend},
        bookingsChartData: {...response.data.bookingsChart},
        salesChartData: {...response.data.salesChart},
        funnelData: {...response.data.funnel},
      }

      // SET IN STORE
      set({
        bookingsPieData: dashboard.bookingsPieData,
        bookingsChartData: dashboard.bookingsChartData,
        salesChartData: dashboard.salesChartData,
        funnelData: dashboard.funnelData,
      })

      //RETURN
      return dashboard
    } catch (err) {
      //
      console.log(err)
    }
    return {
      bookingsPieData: {labels: [], series: [], legend: []},
      bookingsChartData: {
        current: {...initialLineOrBarChartData},
        previous: {...initialLineOrBarChartData},
      },
      salesChartData: {
        current: {...initialLineOrBarChartData},
        previous: {...initialLineOrBarChartData},
      },
    }
  },

  deleteEvent: async (id: string) => {
    // delete event
    await _deleteEvent(id)

    // remove event from the list
    const {events} = get()
    const newEvents = events.filter((event) => event.id !== id)
    set({events: newEvents})

    // unset current event
    get().unsetCurrentEvent()
  },

  handleExists: async (handle: string) => {
    try {
      // check if handle exists
      const response = await _handleExists(handle)
      if (response.data.exists === true) {
        return true
      }
      return false
    } catch {
      return false
    }
  },

  setCurrentEvent: async (id: string, isPublic: boolean = false) => {
    const {events} = get()
    const currentEvent = events.find((event) => event.id === id)

    if (currentEvent) {
      set({currentEvent})
    } else {
      if (isPublic === true) {
        await get().getPublicEvent(id)
      } else {
        await get().getEvent(id)
      }
    }
  },

  unsetCurrentEvent: () => {
    set({currentEvent: initialEvent})
  },

  saveCheckpoint: async (event: string, checkpoint: Partial<CheckpointModel>) => {
    // make sure event id was provided
    if (!event) return

    // if checkpoint is new post it,
    let response: any = {}
    if (!checkpoint.id) {
      delete checkpoint.event
      response = await _postCheckpoint(event, checkpoint)

      // add checkpoint to the list of checkpoints
      const {checkpoints} = get()
      const newCheckpoints = [response.data, ...checkpoints]
      set({checkpoints: newCheckpoints})
    } else {
      delete checkpoint.event
      response = await _patchCheckpoint(event, checkpoint.id, checkpoint)

      // update checkpoints based on id
      const {checkpoints} = get()
      const newCheckpoints = checkpoints.map((checkpoint) => {
        if (checkpoint.id === response.data.id) {
          return response.data
        }
        return checkpoint
      })
      set({checkpoints: newCheckpoints})
    }
  },

  getCheckpoints: async (eventId, query: CheckpointsQuery) => {
    // get checkpoints
    const response = await _getCheckpoints(eventId, query)

    // update checkpoints store
    set({checkpoints: response.data.results})
  },

  deleteCheckpoint: async (eventId, id: string) => {
    // delete checkpoint
    await _deleteCheckpoint(eventId, id)

    // remove checkpoint from the list
    const {checkpoints} = get()
    const newCheckpoints = checkpoints.filter((checkpoint) => checkpoint.id !== id)
    set({checkpoints: newCheckpoints})
  },

  getPublicEvent: async (id: string) => {
    // get event
    const response = await _getPublicEvent(id)
    const event = {...initialEvent, ...response.data}
    set({publicEvent: event})

    return event
  },

  cloneEvent: async (eventId: string) => {
    // clone event
    const response = await _cloneEvent(eventId)
    const newEvent = {
      ...initialEvent,
      ...response.data,
    }

    // add event to the events list
    const {events} = get()
    const newEvents = [newEvent, ...events]
    set({events: newEvents, currentEvent: newEvent})

    return newEvent
  },

  // TICKET FUNCTIONS
  tickets: [],
  saveTicket: async (event: string, ticket: Partial<TicketModel>) => {
    // make sure event id was provided
    if (!event) return

    // if checkpoint is new post it,
    let response: any = {}
    let newTickets: any = []
    let newTicket: any = {}
    let ticketId = ticket.id ? ticket.id : null
    const cps = ticket.checkpointSettings
    const {tickets, checkpoints} = get()

    // CLEAN UP OBJECT

    const payload = pick(ticket, [
      'name',
      'description',
      'price',
      'qty',
      'increment',
      'startDate',
      'endDate',
      'minQty',
      'maxQty',
      'archived',
      'forceMinQty',
      'enableVariablePrice',
      'isPrivate',
    ])

    if (!ticketId) {
      // CREATE NEW TICKET
      response = await _postTicket(event, payload)
      newTicket = response.data

      // CREATE OR UPDATE CHECKPOINT SETTINGS
      if (cps) {
        const postRequests = cps.map((setting) => {
          const postSettings = {
            checkpoint: setting.checkpoint.id,
            qty: setting.qty,
          }
          if (setting.id) {
            return _patchCheckpointSettings(event, newTicket.id, setting.id, postSettings)
          } else {
            return _postCheckpointSettings(event, newTicket.id, postSettings)
          }
        })

        const checkpointSettingsResponses = await Promise.all(postRequests)

        newTicket = {
          ...newTicket,
          checkpointSettings: checkpointSettingsResponses.map((res) => {
            const cp = checkpoints.find((c) => c.id === res.data.checkpoint) || initialCheckpoint
            const r: CheckpointSettingsModel = {
              checkpoint: cp, // Add conditional check to ensure cp is defined
              qty: res.data.qty,
              id: res.data.id,
            }
            return r
          }),
        }
      }

      // ADD NEW TICKET TO THE LIST
      newTickets = [newTicket, ...tickets]
      set({tickets: newTickets})
    } else {
      // UPDATE TICKET
      response = await _patchTicket(event, ticketId, payload)
      newTicket = response.data

      // CREATE OR UPDATE CHECKPOINT SETTINGS
      if (cps) {
        const postRequests = cps.map((setting) => {
          const postSettings = {
            checkpoint: setting.checkpoint.id,
            qty: setting.qty,
          }
          if (setting.id) {
            return _patchCheckpointSettings(event, newTicket.id, setting.id, postSettings)
          } else {
            return _postCheckpointSettings(event, newTicket.id, postSettings)
          }
        })

        const checkpointSettingsResponses = await Promise.all(postRequests)
        newTicket = {
          ...newTicket,
          checkpointSettings: checkpointSettingsResponses.map((res) => {
            const cp = checkpoints.find((c) => c.id === res.data.checkpoint) || initialCheckpoint
            const r: CheckpointSettingsModel = {
              checkpoint: cp, // Add conditional check to ensure cp is defined
              qty: res.data.qty,
              id: res.data.id,
            }
            return r
          }),
        }
      }

      // UPDATE TICKET IN THE LIST
      newTickets = tickets.map((ticket) => {
        if (ticket.id === response.data.id) {
          return newTicket
        }
        return ticket
      })
      set({tickets: newTickets})
    }
  },

  saveTickets: async (eventId: string, newTickets: TicketModel[]) => {
    //  get current tickets
    const {tickets} = get()
    const oldTickets = {...tickets}

    // update tickets
    set({tickets: newTickets})

    // save tickets
    try {
      await _patchTickets(eventId, newTickets)
    } catch (err) {
      // rollback tickets
      set({tickets: oldTickets})
    }
  },

  getTickets: async (eventId: string, query: Pagination) => {
    // get tickets

    const q = pick({...query}, ['limit', 'page'])
    const response = await _getTickets(eventId, q)

    // patch the checkpointSettings with the checkpoint based on id
    const {checkpoints} = get()
    const newTickets = response.data.results.map((ticket) => {
      const newCheckpointSettings = ticket.checkpointSettings.map((setting) => {
        const cp = checkpoints.find((c) => c.id === setting.checkpoint) || initialCheckpoint
        return {
          ...setting,
          checkpoint: cp,
        }
      })
      return {
        ...initialTicket,
        ...ticket,
        checkpointSettings: newCheckpointSettings,
      }
    })

    // UPDATE STORE: tickets and pagination
    set({
      tickets: newTickets,
      ticketsPagination: {
        ...query,
        totalResults: response.data.totalResults,
        totalPages: response.data.totalPages,
      },
    })
    return newTickets
  },

  deleteTicket: async (eventId: string, ticketId: string) => {
    // delete ticket
    await _deleteTicket(eventId, ticketId)

    // remove ticket from the list
    const {tickets} = get()
    const newTickets = tickets.filter((ticket) => ticket.id !== ticketId)
    set({tickets: newTickets})
  },
})

export const eventsStore = create(devtools(createStore))
export const useEventsStore = eventsStore
