import {useEffect, useState} from 'react'
import {FormsQuery, initialFormsQuery, useFormStore} from './core/formStore'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {Toaster} from 'react-hot-toast'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import FormCard from './partials/FormCard'
import {KTIcon} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const FormsPage: React.FC = () => {
  const {getForms, forms, pagination} = useFormStore()
  const [loading, setLoading] = useState<boolean>(false)
  const {selectedAccountsUsers} = useAccountsUsersStore()

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    setLoading(true)
    const payload: FormsQuery = {
      ...initialFormsQuery,
      account: selectedAccountsUsers.account.id,
    }
    getForms(payload).finally(() => {
      setLoading(false)
    })

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  return (
    <>
      {loading && <PageLoadingSpinner />}

      <Toaster />
      {/* HEADER */}
      <div className='row mb-5 mb-lg-0'>
        <div className='d-flex align-items-center justify-content-between p-2 p-xl-5'>
          <div className='d-flex flex-column'>
            <span className='fs-3 fw-bold'>{forms.length} Forms</span>

            <span className='fs-5 text-muted'>
              {forms.length > 0 ? `Found ${pagination.totalResults} forms.` : `no forms found`}
            </span>
          </div>
        </div>
      </div>

      {/* FORMS */}
      {forms.length > 0 ? (
        <div className='row'>
          {forms.map((form) => (
            <FormCard key={form.id} form={form} className='col-12 col-lg-4 col-xl-4' />
          ))}
        </div>
      ) : (
        <div className='d-flex flex-column align-items-center justify-content-center w-100 mt-10 border border-dashed border-gray-300 bg-light rounded p-10'>
          <KTIcon iconName='inbox' className='fs-2x text-muted' />
          <span className='fs-5 text-muted'>
            No forms yet.Sue the button below to create your first form.
          </span>
          <Link className='btn btn-sm btn-info btn-active-secondary mt-5' to={`/forms/create`}>
            Create Form
          </Link>
        </div>
      )}
    </>
  )
}

export {FormsPage}
