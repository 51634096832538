/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useBookingStore} from '../core/bookingsStore'
import {PageLoadingSpinner} from '../../../../_components/PageLoadingSpinner'
import {timeDifference, formatNumber, getCurrencySymbol} from '../../../../_helpers/_helpers'
import clsx from 'clsx'

import {usePatronStore} from '../../patrons/core/patronStore'
import {ManualBookingPaymentButton} from './ManualBookingPaymentButton'
import {TransactionModel, useTransactionStore} from '../../transactions/core/transactionsStore'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'

type Props = {
  onTransactionAdded?: () => void
}

const BookingTransactions: FC<Props> = ({onTransactionAdded}) => {
  const {currentBooking, getBooking} = useBookingStore()

  const {transactions, getTransactions} = useTransactionStore()
  const [isLoading, setIsLoading] = React.useState(true)
  const [showPaymentButton, setShowPaymentButton] = useState<boolean>(false)
  const {isPatronLoggedIn} = usePatronStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()

  useEffect(() => {
    if (currentBooking.id && currentBooking.event.account) {
      setIsLoading(true)
      getTransactions(currentBooking.event.account, {
        booking: currentBooking.id,
        type: 'payment',
      }).finally(() => {
        setIsLoading(false)
      })
    }

    if (currentBooking.stats.ticketsValue > currentBooking.stats.transactionsValue) {
      setShowPaymentButton(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBooking.id, selectedAccountsUsers.account.id])

  const handleTransactionAdded = async () => {
    if (onTransactionAdded) onTransactionAdded()

    if (currentBooking.id) {
      await getBooking(currentBooking.id)
      await getTransactions(selectedAccountsUsers.account.id, {
        booking: currentBooking.id,
        type: 'payment',
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {transactions.length > 0 ? (
            <>
              <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Payments History</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>
                      {transactions.length} transaction
                      {transactions.length > 1 ? 's' : ''}
                    </span>
                  </h3>
                  <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add payment'
                  >
                    {showPaymentButton && !isPatronLoggedIn && (
                      <ManualBookingPaymentButton onTransactionAdded={handleTransactionAdded} />
                    )}
                  </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-150px'>Authors</th>
                          <th className='min-w-140px'>Status</th>
                          <th className='min-w-120px'>Payment Gateway</th>
                          <th className='min-w-100px text-end'>Amount</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {transactions.map((transaction: TransactionModel) => (
                          <tr key={transaction.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {transaction.description}
                                  </span>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {transaction.dateCreated &&
                                      timeDifference(new Date(transaction.dateCreated))}
                                    {` ago`}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span
                                className={clsx('fs-6 py-2 px-4 badge-light-primary', {
                                  'badge-light-warning': transaction.status === 'pending',
                                  'badge-light-success': transaction.status === 'active',
                                  'badge-light-danger': transaction.status === 'cancelled',
                                })}
                              >
                                {transaction.status}
                              </span>
                            </td>
                            <td className='text-end'>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-dark me-2 fs-6 fw-bold'>
                                    {transaction.gateway}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <span className='text-dark fs-6 fw-bold'>
                                  {getCurrencySymbol(currentBooking.event.currency || 'R') +
                                    ' ' +
                                    formatNumber(transaction.amount)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                  {/* end::Table container */}
                </div>
                {/* begin::Body */}
              </div>
            </>
          ) : (
            <>
              {/* no transactions found */}
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <h4 className='text-gray-400 fw-bold fs-2 mt-10'>No transactions found</h4>

                {showPaymentButton && !isPatronLoggedIn && (
                  <ManualBookingPaymentButton onTransactionAdded={handleTransactionAdded} />
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export {BookingTransactions}
