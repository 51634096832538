import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'

import {ActivityDrawer, DrawerMessenger, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {useAppSettingsStore} from '../../app/modules/settings/core/appSettingsStore'

const PublicLayout = () => {
  const location = useLocation()
  const [themeName, setThemeName] = useState('skybookings')
  const {appSettings} = useAppSettingsStore()

  useEffect(() => {
    setThemeName(process.env.REACT_APP_THEME_NAME || 'skybookings')
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          {/* <HeaderWrapper /> */}
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            {/* <Sidebar /> */}
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {/* <ToolbarWrapper /> */}
                <div>
                  <Outlet />
                </div>
              </div>
              {/* <FooterWrapper /> */}
              <div className='app-footer d-flex flex-shrink-1 p-5'>
                <div className='container justify-content-between d-flex'>
                  <span className='text-muted'>&copy;&nbsp;2024&nbsp;{themeName}</span>
                  <span className='text-muted'>{appSettings.version}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}

      <UpgradePlan />
      {/* end:: Modals */}
    </PageDataProvider>
  )
}

export {PublicLayout}
