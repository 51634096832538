import {useEffect, useState} from 'react'
import {BookingTicketModel, ActualTicketModel, useBookingStore} from '../core/bookingsStore'
import {
  formatDateDMY,
  generateTicketQRCode,
  timeDifference,
  trimCharacters,
} from '../../../../_helpers/_helpers'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {useClientStore} from '../../clients/core/clientStore'
import {initialPatron, usePatronStore} from '../../patrons/core/patronStore'
import {Modal} from 'react-bootstrap'
import {PatronBookingTicketAuth} from './PatronBookingTicketAuth'
import {
  FormModel,
  useFormStore,
  initialFormDataQuery,
  FormDataQuery,
  FormDataModel,
  initialFormData,
} from '../../../pages/forms/core/formStore'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import FormRender from '../../../pages/forms/partials/FormRender'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  actualTicket: ActualTicketModel
  bookingTicket: BookingTicketModel
  className: string
  isPublic: boolean
  onShare?: (bookingTicket: BookingTicketModel, ticket: ActualTicketModel) => void
}

const ActualTicket: React.FC<Props> = ({
  actualTicket,
  className,
  isPublic,
  bookingTicket,
  onShare,
}) => {
  const [statusColor, setStatusColor] = useState<string>('warning')
  const [showFormModal, setShowFormModal] = useState(false)
  const [ticketPatron, setTicketPatron] = useState<any>(actualTicket.sentTo)
  const [formData, setFormData] = useState<any>(initialFormData)

  const {isClientLoggedIn} = useClientStore()
  const {isPatronLoggedIn} = usePatronStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {currentBooking, patchBookingTicket} = useBookingStore()
  const {currentForm, getForm, queryFormData, postFormData, patchFormData} = useFormStore()

  // SETUP STATUS COLOR
  useEffect(() => {
    switch (actualTicket.status) {
      case 'pending':
        setStatusColor('warning')
        break
      case 'locked':
        setStatusColor('danger')

        break
      case 'cancelled':
        setStatusColor('danger')

        break
      case 'active':
        setStatusColor('success')

        break
      case 'checked':
        setStatusColor('info')

        break
      default:
        setStatusColor('secondary')
    }

    // eslint-disable-next-line
  }, [])

  // GET FORM DATA
  useEffect(() => {
    if (!isClientLoggedIn && !isPatronLoggedIn && !selectedAccountsUsers.account.id && !isPublic)
      return

    if (currentBooking.event.enablePatronForm && currentBooking.event.patronForm) {
      getForm(currentBooking.event.patronForm).then((form: FormModel) => {
        const queryPayload: FormDataQuery = {
          ...initialFormDataQuery,
          bookingTicket: actualTicket.id!,
          page: 1,
          limit: 1,
        }
        queryFormData(form.id!, queryPayload).then((data) => {
          if (data.length > 0) {
            // set current form data
            setFormData(data[0])
            generateInitialValues()
          }
        })
      })
    }

    // eslint-disable-next-line
  }, [isClientLoggedIn, isPatronLoggedIn, selectedAccountsUsers.account.id, currentBooking.id])

  // HANDLEERS
  const handleSubmit = async (values: any, {setSubmitting}: any) => {
    try {
      // PART 1:: SUBMIT OR UPDATE FORM DATA

      // Format data according to FormDataModel
      const formattedData: FormDataModel = {
        ...formData,
        patron: ticketPatron!,
        data: {...values},
        booking: currentBooking.id, // Add booking ID if available
        event: currentBooking.event.id!,
        form: currentForm.id!,
        bookingTicket: actualTicket.id!,
      }

      // Process form values
      currentForm?.fields?.forEach((field) => {
        if (field.type === 'checkbox') {
          formattedData.data[field.id] = values[field.id].filter(Boolean)
        } else {
          formattedData.data[field.id] = values[field.id]
        }
      })

      let fd
      if (formData.id && currentForm.allowMultiple !== true) {
        // Update existing form data
        fd = await patchFormData(formData.id, formattedData)
      } else {
        fd = await postFormData(currentForm.id!, formattedData)
      }

      // PART 2:: UPDATE BOOKING TICKET

      const abPayload: any = {
        ...actualTicket,
        sentTo: ticketPatron.id,
        formData: fd.id,
      }
      await patchBookingTicket(currentBooking.id!, abPayload)

      // PART 3:: SEND TICKET

      // PART 4. REPORT SUCCESS
      window.location.reload()
    } catch (error) {
      toast.error('Failed to submit form. Please try again or contact admin')
    } finally {
      setSubmitting(false)
    }
  }

  const generateInitialValues = () => {
    if (!currentForm.id || !formData)
      return {
        ...initialFormDataQuery,
      }

    // check if there is a form data
    const initialValues: {[key: string]: any} = {}
    currentForm.fields.forEach((field) => {
      initialValues[field.id] = field.type === 'checkbox' ? [] : ''

      if (actualTicket?.sentTo) {
        switch (field.type) {
          case 'name':
            initialValues[field.id] = actualTicket?.sentTo?.name
            break
          case 'phone':
            initialValues[field.id] = actualTicket?.sentTo?.phone
            break
          case 'email':
            initialValues[field.id] = actualTicket?.sentTo?.email
            break
          default:
            break
        }
      }

      //   check if there is a response
      if (formData.data[field.id]) {
        initialValues[field.id] = formData.data[field.id]
      }
    })
    return initialValues
  }

  // Create dynamic validation schema
  const createValidationSchema = () => {
    const schema: {[key: string]: any} = {}
    currentForm?.fields?.forEach((field) => {
      if (field.required) {
        schema[field.id] =
          field.type === 'checkbox'
            ? Yup.array().min(1, `${field.name} is required`).required(`${field.name} is required`)
            : Yup.string().required(`${field.name} is required`)
      }

      // check email validity
      if (field.type === 'email') {
        schema[field.id] = Yup.string().email('Invalid email format')
      }
    })
    return Yup.object().shape(schema)
  }

  return (
    <div key={`at-${actualTicket.id}`} className={className}>
      {/* IF EVENT ENABLES PATRON FORMS && booking TICKET DOES NOT HAVE FORMDATA */}
      {currentBooking.event.enablePatronForm && !formData.id ? (
        <div
          className={`card border card-custom border-secondary border-1 status-border h-100 danger `}
          style={{borderTopColor: statusColor}}
        >
          <div className={`card-header d-flex justify-content-between align-items-center p-5`}>
            <h3 className='card-title fw-bolder fs-3'>
              {trimCharacters(bookingTicket.ticket.name, 15)}{' '}
            </h3>
            <div className='card-toolbar'>
              <span
                className={clsx(`badge badge-${statusColor} p-3 fs-7`, {
                  'badge-danger': actualTicket.status === 'cancelled',
                  'badge-warning': actualTicket.status === 'pending',
                  'badge-success': actualTicket.status === 'active',
                  'badge-info': actualTicket.status === 'checked',
                })}
              >
                {actualTicket.status}
              </span>
            </div>
          </div>
          <div className='card-body p-5'>
            <div className='d-flex flex-column flex-sm-row bg-light rounded p-5'>
              {currentBooking.status === 'complete' ? (
                <p className='fw-semibold p-3 bg-light rounded'>
                  NB! This ticket requires patron details. Please tap the button below to update
                  patron details and complete the booking.
                </p>
              ) : (
                <p className='fw-semibold p-3 bg-light rounded'>
                  This ticket is not active yet.{' '}
                  {currentBooking.stats.ticketsValue > currentBooking.stats.transactionsValue
                    ? 'Please complete payment to activate this ticket.'
                    : 'Please wait for the organiser to update the status'}
                </p>
              )}
            </div>
          </div>
          {bookingTicket.status === 'active' && !isClientLoggedIn && !isPublic && (
            <div className='card-footer p-3'>
              <button
                type='button'
                onClick={() => setShowFormModal(true)}
                className={clsx('btn btn-sm me-2 btn-active-dark fs-4 w-100', {
                  'btn-outline': actualTicket.sentTo,
                  'btn-danger': !actualTicket.sentTo,
                })}
              >
                <KTIcon iconName='profile-circle' iconType='outline' className='fs-5 me-1' />
                Update Patron Info
              </button>
            </div>
          )}
          {isPublic && (
            <div className='card-footer p-3'>
              {/* assinged by */}
              <div className='d-flex flex-column'>
                <span className='fw-semibold p-3 bg-light fs-5 rounded text-center'>
                  Shared by: {currentBooking.patron.name || 'Not Assigned'}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`card border card-custom border-secondary h-100 border-1 status-border ${statusColor}`}
          style={{borderTopColor: statusColor}}
        >
          <div className={`card-header d-flex justify-content-between align-items-center p-5`}>
            <h3 className='card-title fw-bolder fs-3'>
              {trimCharacters(bookingTicket.ticket.name, 15)}{' '}
            </h3>
            <div className='card-toolbar'>
              <span className={`badge badge-${statusColor} p-3 fs-7`}>{actualTicket.status}</span>
            </div>
          </div>
          <div className='card-body p-3'>
            {currentBooking.status === 'complete' ? (
              <div className='d-flex flex-column'>
                {/* ticket  */}
                <div className='d-flex p-5 fw-100 justify-content-center rounded bg-gray-200'>
                  <div className='d-flex fw-bold fs-5'>
                    {`Patron: ${actualTicket.sentTo?.name || 'Not Assigned'}`}
                  </div>
                </div>

                <div className='d-flex flex-sm-row flex-column mt-5'>
                  {/* QR Code */}
                  <div className='w-100 w-md-50 mb-10 d-flex justify-content-center align-items-center'>
                    <div className={clsx('d-flex justify-content-center w-100')}>
                      <img
                        src={generateTicketQRCode(actualTicket.id)}
                        alt='card2'
                        className='p-2 w-100'
                      />
                    </div>
                  </div>

                  {/* Event Details */}
                  {!isMobileDevice() ? (
                    <div className='d-flex flex-grow-1 flex-column p-0 ms-3 w-100'>
                      {/* event name */}
                      <div className='d-flex align-items-center'>
                        <KTIcon
                          className='fs-3 fs-md-5 me-2'
                          iconName='calendar'
                          iconType='outline'
                        />
                        <div className='fw-semibold p-2 rounded me-3 fs-7 my-1'>
                          {currentBooking.event.name}
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <KTIcon className='fs-3 me-2' iconName='watch' iconType='outline' />
                        <div className='fw-semibold p-2 rounded me-3 fs-7 my-1'>
                          {`Event ${timeDifference(currentBooking.event.startDate)}`}
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <KTIcon className='fs-3 me-2' iconName='time' iconType='outline' />
                        <div className='fw-semibold p-2 rounded me-3 fs-7 my-1'>
                          {`Booked on ${formatDateDMY(actualTicket.dateCreated.toString())}`}
                        </div>
                      </div>

                      <div className='d-flex flex-rows flex-wrap mt-2'>
                        {actualTicket.checks &&
                          actualTicket.checks
                            .filter((check) => check.qty > 0)
                            .map((check, index) => {
                              return (
                                <span
                                  key={`${check.name}-${index}`}
                                  className={clsx('badge p-1', {
                                    'badge-danger': check.status === 'checked',
                                    'badge-success': check.status !== 'checked',
                                  })}
                                >
                                  {`${check.name} (${check.checks}/${check.qty})`}
                                </span>
                              )
                            })}
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex flex-grow-1 flex-column p-0 ms-3 w-100'>
                      {/* event name */}
                      <div className='d-flex align-items-center'>
                        <KTIcon className='fs-2x me-2' iconName='calendar' iconType='outline' />
                        <div className='fw-semibold p-2 rounded me-3 fs-2 my-1'>
                          {currentBooking.event.name}
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <KTIcon className='fs-2x me-2' iconName='watch' iconType='outline' />
                        <div className='fw-semibold p-2 rounded me-3 fs-2 my-1'>
                          {`Event ${timeDifference(currentBooking.event.startDate)}`}
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <KTIcon className='fs-2x me-2' iconName='time' iconType='outline' />
                        <div className='fw-semibold p-2 rounded me-3 fs-2 my-1'>
                          {`Booked on ${formatDateDMY(actualTicket.dateCreated.toString())}`}
                        </div>
                      </div>

                      <div className='d-flex flex-rows flex-wrap mt-2'>
                        {actualTicket.checks &&
                          actualTicket.checks
                            .filter((check) => check.qty > 0)
                            .map((check, index) => {
                              return (
                                <span
                                  key={`${check.name}-${index}`}
                                  className={clsx('badge fs-5', {
                                    'badge-danger': check.status === 'checked',
                                    'badge-success': check.status !== 'checked',
                                  })}
                                >
                                  {`${check.name} (${check.checks}/${check.qty})`}
                                </span>
                              )
                            })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p className='fw-semibold bg-light p-3 fs-5 fs-sm-3 rounded'>
                This ticket is not active yet.{' '}
                {currentBooking.stats.ticketsValue > currentBooking.stats.transactionsValue
                  ? 'Please complete payment to activate this ticket.'
                  : 'Please wait for the organiser to update the status'}
              </p>
            )}
          </div>
          {bookingTicket.status === 'active' &&
            !isPublic &&
            !isClientLoggedIn &&
            (isPatronLoggedIn || selectedAccountsUsers.account.id) && (
              <div className='card-footer p-3'>
                {currentBooking.event.enablePatronForm ? (
                  <button
                    type='button'
                    onClick={() => {
                      setShowFormModal(true)
                    }}
                    className={clsx('btn btn-outline fw-bold me-2 btn-active-dark fs-5 w-100')}
                  >
                    Update Patron Info
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={() => onShare && onShare(bookingTicket, actualTicket)}
                    className={clsx('btn btn-outline fw-bold me-2 btn-active-dark fs-5 w-100')}
                  >
                    Assign Ticket
                  </button>
                )}
              </div>
            )}

          {isPublic && (
            <div className='card-footer p-3'>
              {/* assinged by */}
              <div className='d-flex flex-column'>
                <span className='fw-semibold p-3 bg-light fs-5 rounded text-center'>
                  Shared by: {currentBooking.patron.name || 'Not Assigned'}
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        show={showFormModal}
        onHide={() => setShowFormModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter fs-3 fw-bolder'>
            Update Patron Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='w-100'>
          <div className='d-flex align-items-center flex-column'>
            {/* PATRON SEARCH AUTH */}
            <div className='card card-custom w-100 w-md-600px mb-10'>
              <div className={`card-header`}>
                <h3 className='card-title'>Identify Patron</h3>
              </div>
              <div className='card-body'>
                <PatronBookingTicketAuth
                  patron={actualTicket.sentTo}
                  onLoggedIn={(p) => setTicketPatron(p)}
                  onLogout={() => setTicketPatron({...initialPatron})}
                />
              </div>
            </div>
            {/* FORMIK */}
            <Formik
              initialValues={generateInitialValues()}
              validationSchema={createValidationSchema()}
              onSubmit={handleSubmit}
              enableReinitialize
              fullscreen
            >
              {({isSubmitting, errors, touched}) => (
                <Form className='card card-custom w-100 w-md-600px'>
                  <div className='card-header '>
                    <h3 className='card-title'>{currentForm.name}</h3>
                  </div>
                  <div className='card-body'>
                    <FormRender mode='submit' fields={currentForm.fields} />
                    <button
                      type='submit'
                      className='btn btn-success btn-active-dark w-100'
                      disabled={
                        isSubmitting || !ticketPatron || (errors && Object.keys(errors).length > 0)
                      }
                    >
                      {isSubmitting ? (
                        <>
                          <span className='spinner-border spinner-border-sm me-2'></span>
                          Submitting...
                        </>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ActualTicket
