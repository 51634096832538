import React from 'react'
import {FormDataModel, FormFieldModel, FormModel, useFormStore} from '../core/formStore'
import {
  formatDateDMYHI,
  formatNumber,
  formatPhoneNumber,
  timeDifference,
} from '../../../../_helpers/_helpers'
import Tippy from '@tippyjs/react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import clsx from 'clsx'

type Props = {
  formData: FormDataModel[]
  form: FormModel
  onPageChange?: (page: number) => void
}

type RenderProps = {
  data: FormDataModel
  fields: FormFieldModel[]
}
const RenderRowValue: React.FC<RenderProps> = ({data, fields}) => {
  return (
    <>
      <td key={data.id} className='sticky-column bg-white'>
        <div key={data.id} className='d-flex flex-between'>
          {/* first char */}
          <Tippy content={`Email: ${data.patron?.email} | phone: ${data.patron?.phone}`}>
            <button type='button' className='btn btn-sm btn-light p-1'>
              <div className={clsx('symbol symbol-25px me-2 p-2 me-2')}>
                <span className='symbol-label'>
                  <span className='symbol-label bg-dark fs-7 fw-bold text-light'>
                    {data.patron?.name?.charAt(0)}
                  </span>
                </span>
              </div>

              <span className='text-nowrap'>{data.patron?.name}</span>
            </button>
          </Tippy>
        </div>
      </td>

      <td key={data.id}>
        <Tippy content={formatDateDMYHI(data.dateCreated!)}>
          <span>{timeDifference(data.dateCreated!)}</span>
        </Tippy>
      </td>

      {fields.map((field) => (
        <>
          {/* phone */}
          {field.type === 'phone' && (
            <td key={field.id}>{formatPhoneNumber(data.data[field.id])}</td>
          )}

          {/* date */}
          {field.type === 'date' && (
            <td key={field.id}>
              {data.data[field.id] ? formatDateDMYHI(data.data[field.id]) : '-'}
            </td>
          )}
          {/* number */}
          {field.type === 'number' && (
            <td key={field.id}>{formatNumber(data.data[field.id], 2) || '-'}</td>
          )}

          {/* text */}
          {(field.type === 'text' ||
            field.type === 'name' ||
            field.type === 'select' ||
            field.type === 'radio' ||
            field.type === 'checkbox' ||
            field.type === 'email' ||
            field.type === 'multiline') && <td key={field.id}>{data.data[field.id] || '-'}</td>}
        </>
      ))}
    </>
  )
}

const FormDataTable: React.FC<Props> = ({formData, form, onPageChange}) => {
  const {formDataPagination} = useFormStore()
  const handlePageChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page)
    }
  }
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead>
          <tr className='fw-bolderfs-6 text-gray-800 border-bottom border-gray-200'>
            <th className='min-w-350px  sticky-column bg-white'>Patron</th>
            <th className='min-w-150px'>Date Submitted</th>
            {form.fields.map((field) => (
              <>
                {field.type !== 'readonly' && field.type !== 'separator' && (
                  <td key={field.id} className='min-w-250px'>
                    {field.name}
                  </td>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody className='fw-bold text-gray-600'>
          {formData.map((data, index) => (
            <tr key={`${data.id}-$data${index}`}>
              <RenderRowValue key={data.id} data={data} fields={form.fields} />
            </tr>
          ))}
        </tbody>
      </table>
      {/* ... pagination ... */}

      {/* pagination */}
      {formDataPagination.totalResults &&
        formDataPagination.totalResults > formDataPagination.limit && (
          <div className='pagination mt-5'>
            <PaginationControl
              page={formDataPagination.page}
              total={formDataPagination.totalResults || 0}
              limit={formDataPagination.limit}
              changePage={handlePageChange}
            />
          </div>
        )}
    </div>
  )
}

export default FormDataTable
