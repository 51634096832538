import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import TiptapEditor from '../../../../_components/TipTap/Tiptap'
import placeholders from '../../../../_helpers/_placeholders'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {useAWSStore} from '../../../modules/aws/awsStore'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import {useFormikContext} from 'formik'
import {MessageModel} from '../core/messagesStore'
import {isValidEmail} from '../../../../_helpers/_helpers'

type Props = {
  onSave(from: string, subject: string, content: string): void
  disabled?: boolean
}
const MessageEditorContent: FC<Props> = ({disabled, onSave}) => {
  const {values, setFieldValue} = useFormikContext()
  const v: MessageModel = values as any
  const [show, setShow] = useState(false)
  const [content, setContent] = useState(
    v.content && v.content.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  )
  const [subject, setSubject] = useState(
    v.subject && v.subject.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  )
  const [from, setFrom] = useState(isValidEmail(v.from) ? v.from : '')
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false)
  const {sendVerificationEmail, checkEmailVerification} = useAWSStore()
  const [fromTouched, setFromTouched] = useState(false)
  const [isFromValid, setIsFromValid] = useState(false)
  const [isCheckingEmailVerification, setIsCheckingEmailVerification] = useState(false)

  // load
  useEffect(() => {
    if (!selectedAccountsUsers.account) return

    if (
      selectedAccountsUsers.account.verifiedAWSEmail &&
      isValidEmail(selectedAccountsUsers.account.verifiedAWSEmail)
    ) {
      setFrom(selectedAccountsUsers.account.verifiedAWSEmail)
      setIsFromValid(true)
      setSentVerificationEmail(false)
    } else {
      setFrom('')
      setIsFromValid(false)
      setSentVerificationEmail(false)
      setFieldValue('from', null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountsUsers.account])

  const handleSave = () => {
    setShow(false)
    onSave(from!, subject, content)
  }

  const handleBlur = async (e) => {
    const {value} = e.target

    // check if the email is valid
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setIsFromValid(false)
      return
    } else {
      setIsFromValid(true)
    }

    try {
      // check if the email entered is different from the one in the account
      if (value !== '' && value !== selectedAccountsUsers.account.verifiedAWSEmail) {
        const status = await sendVerificationEmail(selectedAccountsUsers.account.id, value)
        if (status === 'pending') {
          setSentVerificationEmail(true)
        } else {
          setSentVerificationEmail(false)
          setFrom(value)
        }
      } else {
        setSentVerificationEmail(false)
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
      })
    } finally {
      setIsFromValid(true)
    }
  }

  const handleCheckEmailVerification = async () => {
    try {
      setIsCheckingEmailVerification(true)

      await checkEmailVerification(selectedAccountsUsers.account.id, from!)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
      })
    } finally {
      setIsCheckingEmailVerification(false)
    }
  }

  return (
    <>
      <button
        type='button'
        onClick={() => setShow(true)}
        disabled={disabled}
        className='btn btn-outline btn-active-dark'
      >
        Edit Email Content
      </button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Message Content</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='fw-row mb-10'>
            <label className='form-label fw-bold fs-6'>From (Email)</label>
            <input
              name='from'
              onBlur={handleBlur}
              onChange={(e) => {
                setFrom(e.target.value)
                setFromTouched(true)
              }}
              value={fromTouched ? from : selectedAccountsUsers.account?.verifiedAWSEmail}
              className={clsx('form-control form-control', {
                'is-invalid': !isFromValid,
                'is-valid': isFromValid,
              })}
            />

            {sentVerificationEmail && (
              <div className='alert alert-warning text-dark mt-5'>
                <div className='alert-text font-weight-bold'>
                  Please check your emails to verify this email address. The verification email is
                  from <b>Amazon Web Services</b> and includes a link you must click to confirm your
                  email address. After verifying the email, hit the link below to confirm.
                </div>
                <button className='btn btn-info mt-3 btn-sm' onClick={handleCheckEmailVerification}>
                  {isCheckingEmailVerification && (
                    <span className='spinner-border spinner-border-sm me-1'></span>
                  )}
                  Email Verified
                </button>
              </div>
            )}
          </div>

          <div className='fw-row mb-10'>
            <label className='form-label fw-bold fs-6'>Subject</label>
            <TiptapEditor
              name='subject'
              onChange={(content) => setSubject(content)}
              className='form-control form-control'
              showSuggestions={false}
              suggestions={[...placeholders.generic]}
            />
          </div>
          <div className='fw-row mb-10'>
            <label className='form-label fw-bold fs-6'>Body</label>
            <TiptapEditor
              name='content'
              onChange={(content) => setContent(content)}
              showToolbar
              className='form-control form-control'
              showSuggestions
              suggestions={
                v.event
                  ? [...placeholders.generic, ...placeholders.event]
                  : [...placeholders.generic]
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer className='bg-light d-flex justify-content-end'>
          <button onClick={() => setShow(false)} className='btn btn-outline btn-sm btn-active-dark'>
            Close
          </button>
          <button onClick={handleSave} className='btn btn-outline btn-sm btn-active-dark'>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MessageEditorContent
