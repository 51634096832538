import {useLocation, useParams} from 'react-router-dom'
import FormRender, {RenderMode} from './partials/FormRender'
import {FormDataQuery, FormModel, initialFormDataQuery, useFormStore} from './core/formStore'
import {useEffect, useState} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {FormDataModel} from './core/formStore'
import {usePatronStore} from '../../modules/patrons/core/patronStore'
import toast, {Toaster} from 'react-hot-toast'
import {PatronAuth} from '../../modules/patrons/PatronAuth'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'

const PublicForm = () => {
  const {
    currentPublicForm,
    currentFormData,
    setCurrentFormData,
    getFormPublic,
    postFormData,
    patchFormData,
    queryFormData,
    getFormData,
  } = useFormStore()
  const {formId, formDataId} = useParams()

  const {isPatronLoggedIn, currentPatron} = usePatronStore()
  const [renderMode, setRenderMode] = useState<RenderMode>('land')
  const [patronResponses, setPatronResponses] = useState<FormDataModel[]>([])
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [hasLogo, setHasLogo] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const mode = queryParams.get('mode') ?? 'land'

    setRenderMode(mode as RenderMode)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!formId) return

    getFormPublic(formId).then((form) => {
      // set logo
      if (form.account.image && form.account.image !== 'false') {
        setHasLogo(true)
      } else {
        setHasLogo(false)
      }
    })

    if (formDataId && isPatronLoggedIn) {
      getFormData(formId, formDataId)
    }

    // eslint-disable-next-line
  }, [formId, isPatronLoggedIn])

  useEffect(() => {
    if (isPatronLoggedIn && formId !== undefined && formDataId === undefined) {
      setLoading(true)
      generateInitialValues()

      //   get form data
      const payload: FormDataQuery = {
        ...initialFormDataQuery,
        patron: currentPatron.id!,
      }

      queryFormData(formId, payload)
        .then((responses: FormDataModel[]) => {
          setPatronResponses(responses)

          if (responses.length === 1) {
            setCurrentFormData(responses[0])
            setRenderMode('view')

            window.history.replaceState(null, '', `/f/${formId}/${responses[0].id}`)
          } else if (responses.length > 1) {
            // Show list of responses
            setRenderMode('submissions')
          } else {
            setRenderMode('submit')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [currentPatron.id, formId])

  // Generate initial values with proper typing
  const generateInitialValues = () => {
    const initialValues: {[key: string]: any} = {}
    currentPublicForm?.fields?.forEach((field) => {
      initialValues[field.id] = field.type === 'checkbox' ? [] : ''

      if (currentPatron.id) {
        switch (field.type) {
          case 'name':
            initialValues[field.id] = currentPatron.name
            break
          case 'phone':
            initialValues[field.id] = currentPatron.phone
            break
          case 'email':
            initialValues[field.id] = currentPatron.email
            break
          default:
            break
        }
      }

      //   check if there is a response
      if (currentFormData.data[field.id]) {
        initialValues[field.id] = currentFormData.data[field.id]
      }
    })
    return initialValues
  }

  // Create dynamic validation schema
  const createValidationSchema = () => {
    const schema: {[key: string]: any} = {}
    currentPublicForm?.fields?.forEach((field) => {
      if (field.required) {
        schema[field.id] =
          field.type === 'checkbox'
            ? Yup.array().min(1, `${field.name} is required`).required(`${field.name} is required`)
            : Yup.string().required(`${field.name} is required`)
      }

      // check email validity
      if (field.type === 'email') {
        schema[field.id] = Yup.string().email('Invalid email format')
      }
    })
    return Yup.object().shape(schema)
  }

  const handleSubmit = async (values: any, {setSubmitting}: any) => {
    try {
      // Format data according to FormDataModel
      const formattedData: FormDataModel = {
        ...currentFormData,
        patron: currentPatron,
        data: {},
      }

      // Process form values
      currentPublicForm?.fields?.forEach((field) => {
        if (field.type === 'checkbox') {
          formattedData.data[field.id] = values[field.id].filter(Boolean)
        } else {
          formattedData.data[field.id] = values[field.id]
        }
      })

      if (currentFormData.id && currentPublicForm.allowMultiple !== true) {
        // Update existing form data
        patchFormData(currentFormData.id, formattedData)
        setRenderMode('view')
      } else {
        await postFormData(currentPublicForm.id!, formattedData)
        setRenderMode('view')
      }

      toast.success('Form submitted successfully')

      // Add your API submission logic here
    } catch (error) {
      toast.error('Failed to submit form. Please try again or contact admin')
    } finally {
      setSubmitting(false)
    }
  }

  if (!currentPublicForm || !currentPublicForm.id) return null

  return (
    <>
      {loading && <PageLoadingSpinner />}
      <Toaster position='top-center' />

      {/* LANDING PAGE */}
      {renderMode === 'land' ? (
        <div className='d-flex flex-column align-items-center justify-content-center w-100 mt-10 p-10 mb-10'>
          <div className='d-flex flex-column align-items-center justify-content-center  my-5'>
            {hasLogo && (
              <div className='overflow-hidden text-center  p-2 w-75px w-lg-150px flex-shrink-0 d-flex me-3 mb-10 me-lg-5'>
                <img
                  alt='Logo'
                  src={currentPublicForm.account.image}
                  className='app-sidebar-logo-default w-100 h-auto rounded-circle  rounded'
                />
              </div>
            )}
            <div className='d-flex flex-column align-items-center'>
              <h2 className='display-7 display-lg-6 fw-bolder mb-2 text-center '>
                {currentPublicForm.name}
              </h2>
              <p className='fs-5  text-center'>{currentPublicForm.description}</p>
              <div className='d-flex align-items-center my-2 fs-5 text-center'>
                <span className='fw-semibold'>By {currentPublicForm.account.name}</span>
                <KTIcon iconName='verify' iconType='solid' className='ms-2 fs-3 text-primary' />
              </div>
            </div>
          </div>
          {/* button */}
          <button
            type='button'
            className='btn btn-outline fw-bolder btn-active-dark w-300px'
            onClick={() => setRenderMode('submit')}
          >
            Start
          </button>
        </div>
      ) : (
        <>
          {/* LOGIN */}
          {!currentPublicForm.allowAnonymous && !isPatronLoggedIn && renderMode !== 'preview' && (
            <div className='d-flex mw-600px w-100 container flex-column'>
              <div className='card card-custom'>
                <div className='card-body'>
                  <PatronAuth account={currentPublicForm.account.id} />
                </div>
              </div>
            </div>
          )}

          {/* RENDER */}
          {(currentPublicForm.allowAnonymous || isPatronLoggedIn || renderMode === 'preview') && (
            <Formik
              initialValues={generateInitialValues()}
              validationSchema={createValidationSchema()}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({isSubmitting, errors, touched}) => (
                <Form>
                  <div className='d-flex mw-600px w-100 mb-20 container flex-column'>
                    <div className='card card-custom'>
                      <div className='card-header pt-3 d-flex flex-column align-items-start'>
                        {renderMode === 'preview' && (
                          <div className='badge badge-light-info border border-info flex-shrink-1 mb-2'>
                            Preview
                          </div>
                        )}
                        {renderMode === 'view' && (
                          <div className='badge badge-light-success border border-success flex-shrink-1 mb-2'>
                            Submitted
                          </div>
                        )}
                        {currentPublicForm.status !== 'active' && renderMode === 'submit' && (
                          <div className='badge badge-light-danger border border-danger flex-shrink-1 mb-2'>
                            Form is {currentPublicForm.status}
                          </div>
                        )}
                        <h3 className='card-title fs-2 fw-bolder'>
                          {currentPublicForm.name ?? 'Form Submission'}
                        </h3>
                        {currentPublicForm.description && (
                          <p className='fs-5 text-muted mb-4'>{currentPublicForm.description}</p>
                        )}
                      </div>
                      <div className='card-body'>
                        <FormRender
                          mode={renderMode}
                          fields={currentPublicForm.fields}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className='d-flex justify-content-center mt-10'>
                      {renderMode !== 'view' && (
                        <>
                          <button
                            type='submit'
                            className='btn btn-success btn-active-dark w-100'
                            disabled={
                              isSubmitting ||
                              renderMode === 'preview' ||
                              currentPublicForm.status !== 'active' ||
                              (errors && Object.keys(errors).length > 0)
                            }
                          >
                            {isSubmitting ? (
                              <>
                                <span className='spinner-border spinner-border-sm me-2'></span>
                                Submitting...
                              </>
                            ) : (
                              <>
                                {currentFormData.id && currentPublicForm.allowMultiple !== true
                                  ? 'Update Submission'
                                  : 'Submit Form'}
                              </>
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export default PublicForm
