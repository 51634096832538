import {FC, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import PatronSearch from '../../patrons/PatronSearch'
import {PatronModel} from '../../patrons/core/patronStore'
import Tippy from '@tippyjs/react'

type Props = {
  onSearch: (filter: PatronModel) => void // filter: {role, last_login}
  onReset?: () => void
}

const BookingsListPatronSearch: FC<Props> = ({onReset, onSearch}) => {
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  const handleSearch = (patron: any) => {
    onSearch(patron)
  }

  return (
    <>
      <Tippy content='Search for a patron' placement='top'>
        <button
          type='button'
          className='btn btn-icon btn-sm btn-outline btn-active-secondary me-2'
          onClick={() => {
            setShowSearchModal(true)
          }}
        >
          <KTIcon iconType={'outline'} iconName={'magnifier fs-5'} className='text-dark' />
        </button>
      </Tippy>

      <Modal show={showSearchModal} centered onHide={() => setShowSearchModal(false)}>
        <Modal.Body>
          <div className='d-flex align-items-center'>
            <PatronSearch onSelect={handleSearch} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {BookingsListPatronSearch}
