import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {ErrorMessage, Field, Formik, Form, FormikValues} from 'formik'
import SubmitButton from '../../../../_components/SubmitButton'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useLocation} from 'react-router-dom'
import {useAuthStore} from '../core/authStore'
import {userInitialValues, UserModel} from '../../settings/core/accountsUsersStore'
import toast, {Toaster} from 'react-hot-toast'

const AcceptUserInvite = () => {
  // validation Schma
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(3).max(12).required().label('Password'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password')], 'Repeat your password'),
  })

  const location = useLocation()

  const {getUserByToken, acceptUserInvite} = useAuthStore()
  const [token, setToken] = useState('')
  const [user, setUser] = useState<UserModel>(userInitialValues)
  const [invalidToken, setInvalidToken] = useState(false)
  const [isInviteAccepted, setIsInviteAccepted] = useState(false)

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const t = queryParams.get('token') || ''
    setToken(t)
    getUserByToken(t)
      .then((response) => {
        setUser(response)
      })

      .catch((err) => {
        setInvalidToken(true)
      })

    // eslint-disable-next-line
  }, [])

  const handleSubmit = (values: UserModel, formik: FormikValues) => {
    acceptUserInvite(values, token)
      .then((response) => {
        if (response) {
          setIsInviteAccepted(true)
        }
      })

      .catch((err) => {
        toast.error('Failed to accept invite')
      })
  }

  return (
    <>
      <Toaster position='top-center' />
      {invalidToken && (
        <div className='text-center mb-10 card card-custom'>
          {/* begin::Title */}
          <div className='card-header'>
            <h3 className='card-title text-dark w-100 text-center fw-bolder mb-3'>Invalid Token</h3>
          </div>
        </div>
      )}

      {!invalidToken && (
        <Formik
          validationSchema={validationSchema}
          initialValues={user}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form: FormikValues) => {
            return (
              <Form
                noValidate
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                id='kt_login_password_reset_form'
              >
                {/* show form is reset has not failed or is undefined  */}
                {isInviteAccepted === true || user?.isEmailVerified === true ? (
                  <>
                    <div className='text-center mb-10 card card-custom'>
                      {/* begin::Title */}
                      <div className='card-header'>
                        <h3 className='card-title text-dark w-100 text-center fw-bolder mb-3'>
                          Invite Accepted
                        </h3>
                      </div>
                      {/* end::Title */}

                      {/* begin::Link */}
                      <div className='card-body'>
                        <div className='text-gray-500 fw-semibold fs-6 mb-5'>
                          Sign in with your username and password
                        </div>

                        {/* end::Link */}
                        <div className='d-flex w-100'>
                          <Link to='/auth/login' className='w-100'>
                            <button className='btn btn-outline btn-active-dark w-100 btn-sm'>
                              Sign In
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='card card-custom'>
                    <div className='card-header'>
                      {/* begin::Title */}
                      <h3 className='card-title text-dark fw-bolder mb-3'>
                        Setup your new account
                      </h3>
                      {/* end::Title */}
                    </div>

                    <div className='card-body'>
                      {/* START:  FIRST NAME */}
                      <div className='fv-row mb-10 d-flex'>
                        <div className='d-flex flex-row w-100'>
                          {/* firs name */}
                          <div className='d-flex flex-column flex-grow-1 pe-5'>
                            <label className='d-flex align-items-center fw-bold form-label'>
                              <span className='required'>First Name</span>
                            </label>
                            <Field
                              name='firstName'
                              className='form-control border form-control-lg form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='firstName' />
                            </div>
                          </div>

                          {/* lastname */}
                          <div className='d-flex flex-column flex-grow-1'>
                            <label className='d-flex align-items-center form-label fw-bold'>
                              <span className='required'>Last Name</span>
                            </label>
                            <Field
                              name='lastName'
                              className='form-control border form-control-lg form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='lastName' />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Email name */}
                      <div className='fv-row mb-10'>
                        <div className='d-flex w-100'>
                          {/* email */}
                          <div className='d-flex flex-column flex-grow-1'>
                            <label className='d-flex align-items-center fw-bold form-label'>
                              <span className='required'>Email Address</span>
                            </label>

                            <Field
                              name='email'
                              disabled
                              className='form-control form-control-lg border form-control-solid'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='email' />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* begin::Form group Password */}
                      <div className='fv-row mb-10' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                          <div className='position-relative mb-3'>
                            <Field
                              type='password'
                              placeholder='Password'
                              name='password'
                              autoComplete='off'
                              className='form-control form-control-lg form-control-solid'
                            />
                          </div>
                          {/* begin::Meter */}
                          <div
                            className='d-flex align-items-center mb-3'
                            data-kt-password-meter-control='highlight'
                          >
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                          </div>
                          {/* end::Meter */}
                        </div>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='password' />
                        </div>
                        <div className='text-muted'>
                          Use 8 or more characters with a mix of letters, numbers & symbols.
                        </div>
                      </div>
                      {/* end::Form group */}

                      {/* begin::Form group Confirm password */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Confirm Password
                        </label>
                        <Field
                          type='password'
                          placeholder='Password confirmation'
                          autoComplete='off'
                          name='passwordConfirm'
                          className='form-control form-control-lg form-control-solid'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='passwordConfirm' />
                        </div>
                      </div>
                      {/* end::Form group */}

                      {/* begin::submit */}
                      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                        <Link to='/auth/login'>
                          <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-light me-2'
                            disabled={form.isSubmitting || !form.isValid}
                          >
                            Cancel
                          </button>
                        </Link>
                        <SubmitButton
                          isSubmitting={form.isSubmitting}
                          className='btn btn-outline btn-active-dark'
                          label='Accept Invite'
                        />
                      </div>
                      {/* end::Submit group */}
                    </div>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export {AcceptUserInvite}
