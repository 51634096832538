/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {useClientStore} from '../../../../app/modules/clients/core/clientStore'
import {usePatronStore} from '../../../../app/modules/patrons/core/patronStore'

export function GuestHeaderWrapper() {
  const {config, classes} = useLayout()
  const {isClientLoggedIn, currentClient} = useClientStore()
  const {isPatronLoggedIn, currentPatron} = usePatronStore()

  let home = '/'
  if (isClientLoggedIn) {
    home = '/client'
  } else if (isPatronLoggedIn) {
    home = '/patron'
  }

  if (!config.app?.header?.display) {
    return null
  }

  return (
    <>
      {(isClientLoggedIn || isPatronLoggedIn) && (
        <div
          id='kt_app_header'
          className='glass-pane p-2 p-lg-5 shadow-sm position-fixed top-0 start-0 end-0 z-index-2'
        >
          <div
            id='kt_app_header_container'
            className={clsx(
              'app-container',
              classes.headerContainer.join(' '),
              config.app?.header?.default?.containerClass
            )}
          >
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
              <Link className='text-dark fw-bolder fs-5' to={home}>
                Hi {isPatronLoggedIn && currentPatron.name}
              </Link>
            </div>

            <div
              id='kt_app_header_wrapper'
              className='d-flex align-items-stretch justify-content-end flex-lg-grow-1'
            >
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
              <Navbar />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
