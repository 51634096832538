import {AsyncTypeahead, RenderMenuItemChildren} from 'react-bootstrap-typeahead'
import {useNavigate} from 'react-router-dom'
import {useEventsStore} from '../../../modules/events/core/eventsStore'
import React, {useState} from 'react'
import './searchbar.scss'

type Props = {
  onSearch?: (query: string) => void
}

const SearchBar = (props: Props) => {
  // create page like google with a search bar using bs5 flex
  const {onSearch} = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<RenderMenuItemChildren[]>([])
  const {searchEvents} = useEventsStore()
  const navigate = useNavigate()

  const handleChange = (selected: any) => {
    onSearch && onSearch(selected[0].login)
    navigate('/event/' + selected[0].id)
  }
  const handleSearch = async (query: string) => {
    try {
      setIsLoading(true)

      const e = await searchEvents(query)

      const optionItems: any = e.map((event) => {
        return {
          id: event.id,
          organiser: event.organiserName,
          login: event.name,
          artwork: event.artwork,
        }
      })

      setOptions(optionItems)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const filterBy = () => true
  return (
    <div className='d-flex flex-column'>
      <AsyncTypeahead
        className='min-w-lg-450px w-300px searchborder'
        filterBy={filterBy}
        id='search-bar-list'
        isLoading={isLoading}
        labelKey='login'
        minLength={3}
        onChange={handleChange}
        onSearch={handleSearch}
        options={options}
        placeholder='Search events by name or organiser'
        renderMenuItemChildren={(option: any) => (
          <>
            <div className='d-flex bg-hover-light text-wrap'>
              <div
                style={{
                  backgroundImage: `url(${option.artwork})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundColor: 'black',
                }}
                className='w-50px h-50px me-5 symbol symbol-50px'
              />
              <div className='d-flex flex-column'>
                <span className='fs-5 fw-bold text-dark d-flex'>{option.login}</span>
                <span className='fs-7 text-muted'>by {option.organiser}</span>
              </div>
            </div>
          </>
        )}
      />
    </div>
  )
}
export default SearchBar
