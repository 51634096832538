import axios from 'axios'
const AWS_ENDPOINT = '/aws'
const SEND_VERIFICATION_EMAIL = `${AWS_ENDPOINT}/sendVerificationEmail`
const CHECK_EMAIL_VERIFICATION = `${AWS_ENDPOINT}/checkEmailVerification`

export function _sendVerificationEmail(account: string, email: string) {
  const payload: any = {
    account,
    email,
  }

  return axios.post(SEND_VERIFICATION_EMAIL, payload)
}

export function _checkEmailVerification(account: string, email: string) {
  const payload: any = {
    account,
    email,
  }

  return axios.post(CHECK_EMAIL_VERIFICATION, payload)
}
