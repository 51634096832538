import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {getDirtyValues} from '../../../../_helpers/_helpers'
import {
  _getAccount,
  _getAccounts,
  _patchAccount,
  _searchAccounts,
} from '../../../modules/settings/core/accountsUsersApi'
import {
  accountInitialValues,
  AccountModel,
  accountsUsersStore,
} from '../../../modules/settings/core/accountsUsersStore'

export type Pagination = {
  page: number
  limit: number
  totalPages: number
  totalResults: number
}

export const initialPagination: Pagination = {
  page: 1,
  limit: 10,
  totalPages: 1,
  totalResults: 0,
}

export type AccountQuery = {
  sortBy?: string
  limit?: number
  archived?: boolean
  page?: number
  name?: string
  email?: string
  phone?: string
}

export const initialQuery: AccountQuery = {
  sortBy: 'createdAt:desc',
  limit: 10,
  page: 1,
}

type AccountsStore = {
  currentAccount: AccountModel
  accounts: AccountModel[]
  searchResults: AccountModel[]
  pagination: Pagination
  query: AccountQuery

  setQuery: (query: Partial<AccountQuery>) => void
  saveAccount: (event: Partial<AccountModel>) => Promise<void>
  getAccounts: (query: AccountQuery) => Promise<void>
  getMoreAccounts: () => Promise<void>
  getAccount: (id: string) => Promise<AccountModel>
  setCurrentAccount: (id: string) => void
  unsetCurrentAccount: () => void
  searchAccounts(query: string): Promise<AccountModel[]>
  updateAccount: (account: Partial<AccountModel>) => Promise<void>
}

const createStore: StateCreator<AccountsStore> = (set, get) => ({
  currentAccount: {...accountInitialValues},
  accounts: [],
  searchResults: [],
  pagination: {...initialPagination},
  query: {...initialQuery},

  updateAccount: async (newAccount: Partial<AccountModel>) => {
    // get current account
    const {account} = accountsUsersStore.getState().selectedAccountsUsers

    // update selectedAccountsUsers.account
    accountsUsersStore.setState((state) => {
      return {
        selectedAccountsUsers: {
          ...state.selectedAccountsUsers,
          account: {...state.selectedAccountsUsers.account, ...account},
        },
      }
    })

    // update account in the list
    const newAccounts = get().accounts.map((a) => {
      if (a.id === account.id) {
        return {...a, ...newAccount}
      }
      return account
    })

    set({accounts: newAccounts})
  },

  setQuery: (query: Partial<AccountQuery>) => {
    set({query: {...get().query, ...query}})
  },

  saveAccount: async (account: Partial<AccountModel>) => {
    // get dirty values
    const {currentAccount} = get()
    const dirtyValues = getDirtyValues(account, currentAccount)

    // if account is new, post it
    let response: any = {}

    response = await _patchAccount(currentAccount.id, dirtyValues)

    // update the account in the list
    const newAccounts = get().accounts.map((account) => {
      if (account.id === currentAccount.id) {
        return {...account, ...response.data}
      }
      return account
    })
    set({accounts: newAccounts})
  },

  searchAccounts: async (query: string) => {
    // get events
    const response = await _searchAccounts({query})

    // start each result with initial Values (to avoid uncontrolled inputs error)
    const accounts = response.data.map((account: AccountModel) => {
      return {...accountInitialValues, ...account}
    })

    set({searchResults: accounts})

    return accounts
  },

  getAccounts: async (query) => {
    // get accounts

    const response = await _getAccounts(query)

    // sort accounts by response.data.results.stats.eventsBookingsCard.totalEvents desc
    response.data.results.sort((a: AccountModel, b: AccountModel) => {
      return b.stats?.eventsBookingsCard.totalEvents - a.stats?.eventsBookingsCard.totalEvents
    })

    const pagination: Pagination = {
      page: response.data.page,
      limit: response.data.limit,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }

    set({
      query,
      accounts: response.data.results,
      pagination,
    })
  },

  getMoreAccounts: async () => {
    // update query
    const {query, pagination} = get()
    const newQuery = {...query, page: pagination.page + 1}
    set({query: newQuery})

    // get accounts and append them to the accounts list
    const response = await _getAccounts({query: newQuery})
    const newAccounts = response.data.results
    const allAccounts = [...get().accounts, ...newAccounts]
    set({accounts: allAccounts})

    // update pagination
    const newPagination: Pagination = {
      ...pagination,
      page: response.data.page,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }
    set({pagination: newPagination})
  },

  getAccount: async (id: string) => {
    // get account
    const response = await _getAccount(id)
    set({currentAccount: {...response.data}})

    return response.data
  },

  setCurrentAccount: (id: string) => {
    const {accounts} = get()
    const currentAccount = accounts.find((account) => account.id === id)
    if (currentAccount) {
      set({currentAccount})
    }
  },
  unsetCurrentAccount: () => {
    set({currentAccount: accountInitialValues})
  },
})

export const accountsStore = create(devtools(createStore))
export const useAccountsStore = accountsStore
