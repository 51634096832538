import {Route, Routes, Navigate} from 'react-router-dom'
import {PublicLayout} from '../../_metronic/layout/PublicLayout'
import {EventPublicPage} from '../modules/events/EventPublicPage'
import {PublicBookingPage} from '../modules/bookings/PublicBookingPage'
import ScanPage from '../modules/scanners/ScanPage'
import {PublicBookingTicketsPage} from '../modules/bookings/PublicBookingTicketsPage'
import {PublicTicketPage} from '../modules/bookings/PublicTicketPage'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        {/* ALL ROUTE INCLUDE event/ or wallet/ OR ticket/ ) */}
        <Route path=':eventId' element={<EventPublicPage />} />
        <Route path=':eventId/booking' element={<PublicBookingPage />} />

        {/* ticket */}
        <Route path=':eventId/ticket/:ticketId' element={<PublicTicketPage />} />
        <Route path=':eventId/booking/:bookingId' element={<PublicBookingTicketsPage />} />

        {/* EVENT SCANNER  */}
        <Route path='/:eventId/scanners/:scannerId/scan/*' element={<ScanPage />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/public/404' />} />
      </Route>
    </Routes>
  )
}

export {PublicRoutes}
