/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {BookingTicketModel, ActualTicketModel} from '../core/bookingsStore'
import {PublicEventModel} from '../../events/core/eventsStore'
import ActualTicket from './ActualTicket'

type Props = {
  bookingTicket: BookingTicketModel
  event: Partial<PublicEventModel>
  enableSharing?: boolean
  isPublic: boolean
  className: string
  onShare?: (bookingTicket: BookingTicketModel, ticket: ActualTicketModel) => void
}

const BookingTicketItem: FC<Props> = ({bookingTicket, className, isPublic, onShare}) => {
  return (
    <>
      {bookingTicket.actualTickets &&
        bookingTicket.actualTickets.map((actualTicket: ActualTicketModel) => (
          <ActualTicket
            key={actualTicket.id}
            actualTicket={actualTicket}
            bookingTicket={bookingTicket}
            onShare={onShare}
            className={className}
            isPublic={isPublic}
          />
        ))}
    </>
  )
}

export {BookingTicketItem}
