/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

import {pluralise, trimCharacters} from '../../../../_helpers/_helpers'
import {Link} from 'react-router-dom'

import {formStatusLabel} from '../../../../_helpers/_templateHelpers'

import {FormModel} from '../core/formStore'
import clsx from 'clsx'

type Props = {
  form: FormModel
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const FormCard: FC<Props> = ({
  form,
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [bookingsPCT, setBookingsPCT] = useState<number>(0)

  // RENDER
  useEffect(() => {
    refreshChart()

    // calculate bookings percentage
    const pct = Math.floor((form.stats.responses / (form.stats.views || 1)) * 100)
    setBookingsPCT(pct)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  return (
    <div className={`p-2 p-xl-5 ps-0 ${className}`}>
      <Link
        to={`/forms/${form.id}`}
        className={`card card-flush w-100 d-flex h-100 min-h-235px border-1 bg-hover-light shadow-none border-secondary`}
      >
        <div className='card-body d-flex'>
          <div className='flex-column w-100 pe-5'>
            <div className='d-flex fs-3 fw-bold align-items-center mb-2'>
              {trimCharacters(form.name, 45)}
            </div>
            <div className='d-flex'>
              {/* status */}
              <div
                className={clsx('badge me-2', {
                  'badge-secondary': form.status === 'draft',
                  'badge-success': form.status === 'active',
                  'badge-warning': form.status === 'paused',
                })}
              >
                {form.status}
              </div>

              {/* responses */}
              <div className='badge me-2 badge-secondary'>{form.stats.responses} responses</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
  drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, 100 / 150)
  drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 250)
}

export default FormCard
