import {Field} from 'formik'
import clsx from 'clsx'
import {accountsUsersStore} from '../app/modules/settings/core/accountsUsersStore'
import {authStore} from '../app/modules/auth/core/authStore'

const HandleInput = (props: any) => {
  const {name, inputGroupText, ...rest} = props

  return (
    <Field name={name}>
      {({field, form, meta}: any) => {
        // if user is logged in, ignore the handle associated with the logged in account
        const {getAccount} = accountsUsersStore()
        const {handleExists} = authStore()
        const account = getAccount()

        return (
          // input group with bootstrap
          <div className='input-group'>
            <span className='input-group-text'>{!inputGroupText ? '@' : inputGroupText}</span>

            <input
              type='text'
              id={field.name}
              {...field}
              {...rest}
              className={clsx('form-control ', {'is-invalid': meta.error && meta.touched === true})}
              onBlur={async (event) => {
                if (!meta.error) {
                  // make sure handle is not the same as current account handle (if logged in)

                  if (account !== undefined) {
                    const {handle} = account

                    if (handle !== event.target.value) {
                      form.setFieldTouched(field.name, true, true)
                      form.setSubmitting(true)

                      // check if handle exists
                      const exists = await handleExists(event.target.value)
                      if (exists) {
                        form.setFieldError(
                          field.name,
                          'This handle is not available.  Please try another one.'
                        )
                      }
                      form.setSubmitting(false)
                    }
                  } else {
                    form.setFieldTouched(field.name, true, true)
                    form.setSubmitting(true)

                    // check if handle exists
                    if (await handleExists(event.target.value)) {
                      form.setFieldError(
                        field.name,
                        'This handle is not available.  Please try another one.'
                      )
                    }
                    form.setSubmitting(false)
                  }

                  if (props.onBlur) {
                    props.onBlur(event)
                  }
                }
              }}
            />
          </div>
        )
      }}
    </Field>
  )
}

export default HandleInput
