/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useEventsStore} from './core/eventsStore'
import {EventOptions} from './EventSinglePartials/EventOptions'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {eventStatusLabel} from '../../../_helpers/_templateHelpers'
import Swal from 'sweetalert2'
import EventThumbnail from '../../../_components/EventThumbnail'
import {trimCharacters} from '../../../_helpers/_helpers'
import {BreadcrumbModel, useAppSettingsStore} from '../settings/core/appSettingsStore'
import {AnalyticsTicketsProgressCards} from './EventAnalyticsPartials/AnalyticsTicketsProgressCard'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'

const SingleEventPage: FC = () => {
  const {cloneEvent, getEvent, deleteEvent, currentEvent} = useEventsStore()
  const {eventId} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  useEffect(() => {
    // if current event is not set
    if (eventId) {
      setIsLoading(true)
      getEvent(eventId).finally(() => {
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [])

  // Setup Breadcrumbs
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: 'Event',
        title: trimCharacters(currentEvent.name, 12),
        link: `/events/${currentEvent.id}`,
      },
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Event')

    // eslint-disable-next-line
  }, [currentEvent])

  const handleOptionClick = (option: string) => {
    if (option === 'delete') {
      if (currentEvent.stats.bookingsCount > 0) {
        Swal.fire({
          title: 'Cannot delete event',
          text: 'Event has bookings and cannot be deleted',
          icon: 'error',
        })
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Event will be deleted and cannot be recovered',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.isConfirmed) {
            deleteEvent(currentEvent.id || '')
              .catch(() => {
                Swal.fire('Error', 'Could not delete event', 'error')
              })
              .then(() => {
                Swal.fire('Deleted!', 'Event has been deleted.', 'success').then(() => {
                  navigate('/events')
                })
              })
          }
        })
      }
    }

    // clone
    if (option === 'clone') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Event will be cloned',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, clone it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.isConfirmed) {
          cloneEvent(currentEvent.id || '')
            .catch(() => {
              Swal.fire('Error', 'Could not clone event', 'error')
            })

            .then((event: any) => {
              Swal.fire('Cloned!', 'Event has been cloned.', 'success').then(() => {
                navigate(`/events/${event.id}/edit`, {replace: true})
              })
            })
        }
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {/* HEADERS */}

          <div className='page-title p-lg-5 d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-row'>
                <EventThumbnail showAvailabilityIndicator={true} event={currentEvent} size={50} />
                <div className='d-flex flex-column'>
                  <h3 className='fs-1 fw-bolder mb-0'>{currentEvent.name}</h3>
                  <div className='d-flex align-items-start'>
                    <span className='text-muted'>{eventStatusLabel(currentEvent)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* begin::Row */}
          <div className='d-flex w-100 flex-lg-nowrap flex-column-reverse flex-lg-row'>
            {/* begin::Col */}

            <EventOptions event={currentEvent} onClick={handleOptionClick} />

            {/* end::Col */}

            {/* begin::Col */}
            <div className='d-flex flex-column align-items-start w-lg-80'>
              {/* <EventSalesOrBookingsCard
                className='m-3 w-100 me-10 ms-0 w-lg-450px'
                chartType='auto'
                chartColor='black'
              /> */}
              {!isMobileDevice() ? (
                <div className='d-flex flex-grow-1 align-items-start'>
                  <AnalyticsTicketsProgressCards
                    height={300}
                    showPCT={false}
                    className='m-3 w-100 me-10 ms-0 w-lg-450px'
                  />
                </div>
              ) : (
                <div className='d-flex flex-grow-1 w-100 align-items-start px-5 my-10 card card-flush'>
                  <AnalyticsTicketsProgressCards
                    height={300}
                    showPCT={false}
                    className='m-3 w-100 me-10 ms-0 w-lg-450px'
                  />
                </div>
              )}

              {/* <ListsWidget26 className='h-lg-50' /> */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

export {SingleEventPage}
