/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useBookingStore} from './core/bookingsStore'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import {BookingHeader} from './SingleEventPartials/BookingHeader'
import {BookingTransactions} from './SingleEventPartials/BookingTransactions'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'
import BookingTickets from './SingleEventPartials/BookingTickets'
import {useEventsStore} from '../events/core/eventsStore'

export function SingleBookingPage() {
  const {getBooking, currentBooking} = useBookingStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const {bookingId} = useParams<{bookingId: string}>()

  useEffect(() => {
    if (bookingId) {
      getBooking(bookingId)
    }
    // eslint-disable-next-line
  }, [])

  // Setup Breadcrumbs
  useEffect(() => {
    const crumbs: PageDataModel[] = [
      {
        object: 'Events',
        title: 'Events',
        link: '/events',
      },
      {
        object: `Bookings`,
        title: `Bookings`,
        link: `/events/${currentBooking.event?.id}/bookings`,
      },
      {
        object: `Booking`,
        title: `${currentBooking.patron.name}`,
        link: `/bookings/${currentBooking.id}`,
      },
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Booking')

    // eslint-disable-next-line
  }, [currentBooking])

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='tickets'
          element={
            <>
              <BookingHeader />
              <BookingTickets />
            </>
          }
        />
        <Route
          path='transactions'
          element={
            <>
              <BookingHeader />
              <BookingTransactions />
            </>
          }
        />

        <Route index element={<Navigate to={`/bookings/${bookingId}/tickets`} />} />
      </Route>
    </Routes>
  )
}
