import {Field, useFormikContext} from 'formik'
import DateInput from '../../../../_components/DateInput'
import {FormFieldModel} from '../core/formStore'
import clsx from 'clsx'
import {useEffect} from 'react'
import PhoneField from '../../../../_components/PhoneField'

export type RenderMode = 'land' | 'submit' | 'view' | 'preview' | 'submissions'

type FormRenderProps = {
  fields: FormFieldModel[]
  mode: RenderMode
  errors?: any
  touched?: any
}

const FormRender: React.FC<FormRenderProps> = ({mode, fields, errors, touched}) => {
  useEffect(() => {}, [fields])
  const {values} = useFormikContext()

  const renderField = (field: FormFieldModel) => {
    if (!field || !field.id) {
      console.error('Field is missing ID:', field)
      return null
    }

    let renderedField: JSX.Element
    if (mode === 'view') {
      renderedField = (
        <>
          {field.type !== 'readonly' && field.type !== 'separator' && (
            <div className='form-control-plaintext bg-secondary p-5 fw-bold rounded'>
              {(values as any)[field.id] || '-'}
            </div>
          )}
        </>
      )
    } else {
      switch (field.type) {
        case 'separator':
          renderedField = (
            <div className='separator separator-dashed my-10'>
              <div className='separator separator-content'>
                <div className='fw-bold bg-secondary text-dark rounded p-2 text-nowrap'>
                  {field.name}
                </div>
              </div>
            </div>
          )
          break

        case 'readonly':
          renderedField = (
            <div className='form-control-plaintext bg-light border border-secondary border-dashed p-5 rounded my-10'>
              <p className='fs-5 fw-bold'>{field.name}</p>
              <p className='fs-7 fw-normal'>{field.description}</p>
            </div>
          )
          break

        case 'phone':
          renderedField = (
            <>
              <PhoneField disabled={mode === 'preview'} name={field.id} />
              {errors?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </>
          )
          break

        case 'email':
          renderedField = (
            <>
              <Field
                type='email'
                className={clsx('form-control', {
                  'is-invalid': errors?.[field.id] && touched?.[field.id],
                })}
                disabled={mode === 'preview'}
                name={field.id}
              />
              {errors?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </>
          )
          break

        case 'date':
          renderedField = (
            <>
              <DateInput
                disabled={mode === 'preview'}
                name={field.id}
                className={clsx('form-control', {
                  'is-invalid': errors?.[field.id] && touched?.[field.id],
                })}
              />
              {errors?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </>
          )
          break
        case 'select':
          renderedField = (
            <div>
              <Field
                disabled={mode === 'preview'}
                as='select'
                name={field.id}
                className={clsx('form-select', {
                  'is-invalid': errors?.[field.id] && touched?.[field.id],
                })}
              >
                <option value=''>Select an option</option>
                {(field.options || []).map((option: string, i: number) => (
                  <option key={`${field.id}-option-${i}`} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              {errors?.[field.id] && touched?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </div>
          )
          break
        case 'radio':
          renderedField = (
            <div>
              <div role='group' className='d-flex' aria-labelledby={field.id}>
                {(field.options || []).map((option: string, i: number) => (
                  <div key={`${field.id}-radio-${i}`} className='form-check me-2'>
                    <Field
                      disabled={mode === 'preview'}
                      type='radio'
                      name={`${field.id}`}
                      value={option}
                      className='form-check-input'
                      id={`${field.id}-radio-${i}`}
                    />
                    <label
                      htmlFor={`${field.id}-radio-${i}`}
                      className='form-check-label text-dark'
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>
              {errors?.[field.id] && touched?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </div>
          )
          break
        case 'checkbox':
          renderedField = (
            <div>
              <div role='group' className='d-flex' aria-labelledby={field.id}>
                {(field.options || []).map((option: string, i: number) => (
                  <div key={`${field.id}-checkbox-${i}`} className='form-check me-2'>
                    <Field
                      disabled={mode === 'preview'}
                      type='checkbox'
                      name={`${field.id}[${i}]`}
                      value={option}
                      className='form-check-input'
                      id={`${field.id}-checkbox-${i}`}
                    />
                    <label
                      htmlFor={`${field.id}-checkbox-${i}`}
                      className='form-check-label text-dark'
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>
              {errors?.[field.id] && touched?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </div>
          )
          break
        default:
          renderedField = (
            <div>
              <Field
                disabled={mode === 'preview'}
                name={field.id}
                type={field.type === 'multiline' ? 'textarea' : field.type}
                className='form-control'
                as={field.type === 'multiline' ? 'textarea' : 'input'}
              />
              {errors?.[field.id] && touched?.[field.id] && (
                <div className='text-danger small mt-2'>{errors[field.id]}</div>
              )}
            </div>
          )
      }
    }

    const response: JSX.Element = (
      <>
        {field.type === 'readonly' || field.type === 'separator' ? (
          renderedField
        ) : (
          <div className='w-100 form-group d-flex flex-column mt-5 mb-10'>
            <label
              className={clsx('form-label fs-5 fw-bold', {
                'mb-2': field.description,
                'mb-5': !field.description,
              })}
              htmlFor={field.id}
            >
              {field.name}
              {field.required && <span className='text-danger ms-1'>*</span>}
            </label>
            {field.description && <div className='text-muted small mb-5'>{field.description}</div>}
            {renderedField}
          </div>
        )}
      </>
    )

    return <>{response}</>
  }

  return (
    <div className='preview-content'>
      {fields.map((field, index) => (
        <div key={field.id || `field-${index}`} className='mb-4'>
          {renderField(field)}
        </div>
      ))}
    </div>
  )
}

export default FormRender
