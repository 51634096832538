import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {useEventsStore} from './modules/events/core/eventsStore'
import {isHandle} from '../_helpers/_helpers'
import {useFormStore} from './pages/forms/core/formStore'

const App = () => {
  const {getEventByHandle} = useEventsStore()
  const {getFormByHandle} = useFormStore()
  const [isLoading, setIsLoading] = useState(true)

  // get anything after / in the url
  useEffect(() => {
    const possibleEventHandle = window.location.pathname.split('/')[1]
    // check if isHandle
    if (possibleEventHandle && isHandle(possibleEventHandle)) {
      // get event by handle
      getEventByHandle(possibleEventHandle).then((exists) => {
        if (exists && exists.id) {
          window.location.href = `/event/${exists.id}`
        } else {
          // search form by handle
          getFormByHandle(possibleEventHandle).then((exists) => {
            if (exists && exists.id) {
              window.location.href = `/f/${exists.id}`
            } else {
              window.location.href = '/404'
            }
          })
          // createBrowserHistory().push('/')
          setIsLoading(false)
        }
      })
    } else {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <ThemeModeProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </ThemeModeProvider>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      )}
    </>
  )
}

export {App}
