import {useEffect, useState} from 'react'
import {Button, Form, Modal, Accordion} from 'react-bootstrap'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import {v4 as uuidv4} from 'uuid'
import {FormFieldModel, initialFormField} from '../core/formStore'
import {useFormikContext} from 'formik'
import clsx from 'clsx'

type FieldType =
  | 'text'
  | 'multiline'
  | 'number'
  | 'name'
  | 'email'
  | 'phone'
  | 'date'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'separator'
  | 'readonly'

type FormBuilderProps = {
  onSubmit?: (fields: FormFieldModel[]) => void
  disabled?: boolean
}

const FormBuilder: React.FC<FormBuilderProps> = (props) => {
  const formikContext: any = useFormikContext()

  const [fields, setFields] = useState<FormFieldModel[]>([])
  const [activeAccordionKey, setActiveAccordionKey] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [newField, setNewField] = useState<FormFieldModel>({...initialFormField})
  const [editingField, setEditingField] = useState<string | null>(null)

  const fieldTypes: {label: string; value: FieldType}[] = [
    {label: 'Text', value: 'text'},
    {
      label: 'Multiline Text',
      value: 'multiline',
    },
    {label: 'Number', value: 'number'},
    {label: 'Respondent Name', value: 'name'},
    {label: 'Email', value: 'email'},
    {label: 'Phone', value: 'phone'},
    {label: 'Date', value: 'date'},
    {label: 'Dropdown', value: 'select'},
    {label: 'Multiple Choice', value: 'radio'},
    {label: 'Checkboxes', value: 'checkbox'},
    {label: 'Separator', value: 'separator'},
    {label: 'Read Only Text (Only description will show)', value: 'readonly'},
  ]

  useEffect(() => {
    setFields(formikContext.values.fields as FormFieldModel[])
  }, [formikContext.values.fields])

  // Field management handlers
  const handleAddField = (): void => {
    const fieldToAdd = {
      ...newField,
      options: ['select', 'radio', 'checkbox'].includes(newField.type) ? newField.options : [],
    }

    const updatedFields = editingField
      ? fields.map((f) => (f.id === editingField ? {...fieldToAdd, id: editingField} : f))
      : [...fields, {...fieldToAdd, id: uuidv4()}]

    setFields(updatedFields as FormFieldModel[])
    setShowModal(false)

    setEditingField(null)

    // add field
    if (props.onSubmit && formikContext.values.id) props.onSubmit(updatedFields)
  }

  const handleAddOption = () => setNewField({...newField, options: [...newField.options, '']})

  const handleDeleteOption = (index: number) => {
    setNewField({...newField, options: newField.options.filter((_, i) => i !== index)})
    if (props.onSubmit && formikContext.values.id) props.onSubmit(fields)
  }

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...newField.options]
    newOptions[index] = value
    setNewField({...newField, options: newOptions})
  }

  const handleEditField = (field: FormFieldModel) => {
    setNewField(field)
    setEditingField(field.id)
    setShowModal(true)
  }

  const handleDeleteField = (id: string) => {
    const items = fields.filter((f) => f.id !== id)
    setFields(items)

    if (props.onSubmit && formikContext.values.id) props.onSubmit(items)
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const items = Array.from(fields)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setFields(items)

    if (props.onSubmit && formikContext.values.id) props.onSubmit(items)
  }

  // Design mode rendering
  const renderDesignMode = () => (
    <div className='mt-3'>
      <DragDropContext disabled={props.disabled} onDragEnd={handleDragEnd}>
        <Droppable droppableId='fields'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className='mt-3'>
              <Accordion
                activeKey={activeAccordionKey}
                onSelect={(e) => setActiveAccordionKey(e as string)}
              >
                {fields &&
                  fields.map((field, index) => (
                    <Draggable
                      key={`${field.id}-${index}`}
                      draggableId={`${field.id}-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <Accordion.Item
                            eventKey={`${field.id}-${index}`}
                            className={clsx('mb-3', {
                              'formbuilder-separator': field.type === 'separator',
                            })}
                          >
                            <div className='d-flex align-items-center dragger'>
                              <div
                                {...provided.dragHandleProps}
                                className={clsx('p-5')}
                                style={{cursor: 'grab'}}
                              >
                                ☰
                              </div>
                              <Accordion.Header className={clsx('w-100')}>
                                {`${field.name}` || `Field ${index + 1}`}
                                {field.required === true ? ' *' : ''}
                                {field.type === 'separator' && (
                                  <span className='badge bg-secondary border border-dark text-dark ms-2'>
                                    Separator
                                  </span>
                                )}
                              </Accordion.Header>
                            </div>
                            <Accordion.Body>
                              <div className='d-flex text-wrap align-items-center'>
                                <Form.Group className='d-flex flex-column flex-grow-1 me-2'>
                                  {field.description && (
                                    <Form.Text className='d-block mb-2 fw-7 text-muted'>
                                      {field.description}
                                    </Form.Text>
                                  )}
                                  <div className='d-flex'>{renderFormikField(field)}</div>
                                </Form.Group>
                                <div className='d-flex justify-content-end gap-2 mb-2'>
                                  <Button
                                    type='button'
                                    variant='warning'
                                    size='sm'
                                    onClick={() => handleEditField(field)}
                                  >
                                    Edit
                                  </Button>
                                  {field.removable !== false && (
                                    <Button
                                      variant='danger'
                                      size='sm'
                                      onClick={() => handleDeleteField(field.id)}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </div>
                      )}
                    </Draggable>
                  ))}
              </Accordion>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* add field */}
      <button
        className='btn btn-outline btn-active-dark btn-sm me-2'
        type='button'
        onClick={() => setShowModal(true)}
      >
        Add Field
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editingField ? 'Edit' : 'Add'} Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Field Name *</Form.Label>
            <Form.Control
              required
              value={newField.name}
              onChange={(e) => setNewField({...newField, name: e.target.value})}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as='textarea'
              value={newField.description || ''}
              onChange={(e) => setNewField({...newField, description: e.target.value})}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Field Type *</Form.Label>
            <Form.Select
              value={newField.type}
              onChange={(e) => {
                const type = e.target.value as FieldType
                setNewField({
                  ...newField,
                  type,
                  options: ['select', 'radio', 'checkbox'].includes(type) ? newField.options : [],
                })
              }}
            >
              {fieldTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Check
            type='switch'
            label='Required field'
            checked={newField.required}
            onChange={(e) => setNewField({...newField, required: e.target.checked})}
            className='mb-3'
          />

          {['select', 'radio', 'checkbox'].includes(newField.type) && (
            <div className='mb-3'>
              <Form.Label className='fw-bold w-100'>Options *</Form.Label>
              {newField.options.map((option, index) => (
                <div key={index} className='d-flex gap-2 mb-2'>
                  <Form.Control
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                  />
                  <Button variant='danger' onClick={() => handleDeleteOption(index)}>
                    ×
                  </Button>
                </div>
              ))}
              <button className='btn-outline btn btn-sm btn-active-dark' onClick={handleAddOption}>
                Add Option
              </button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-outline btn-active-secondary me-2'
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            className='btn btn-sm btn-outline btn-active-dark'
            type='button'
            onClick={handleAddField}
          >
            {editingField ? 'Save Changes' : 'Add Field'}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )

  const renderFormikField = (field: FormFieldModel) => {
    const commonProps = {
      disabled: true,
      className: 'form-control',
    }

    // convert field name to camelCase and remove spaces
    const fieldName = field.name.replace(/\s/g, '').toLowerCase()

    switch (field.type) {
      case 'separator':
        return (
          <div className='d-flex'>
            <div className='badge badge-light p-5 fs-7 me-2'>Field Type: Separator</div>
          </div>
        )

      case 'select':
        return (
          <Form.Select {...commonProps} name={fieldName}>
            <option value=''>Select an option</option>
            {field.options.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        )
      case 'radio':
        return (
          <div className='d-flex gap-2'>
            {field.options.map((option, i) => (
              <Form.Check
                className='me-2'
                key={i}
                type='radio'
                label={option}
                name={fieldName}
                disabled
              />
            ))}
          </div>
        )
      case 'checkbox':
        return (
          <div className='d-flex gap-2'>
            {field.options.map((option, i) => (
              <Form.Check className='me-2' key={i} type='checkbox' label={option} disabled />
            ))}
          </div>
        )
      default:
        return (
          <div className='d-flex'>
            <div className='badge badge-light p-5 fs-7 me-2'>Field Type: {field.type}</div>
            <div className='badge badge-light p-5 fs-7 me-2'>
              Required: {field.required ? 'Yes' : 'No'}
            </div>
          </div>
        )
    }
  }

  return (
    <div className='d-flex w-100'>
      <div className='col-12'>{renderDesignMode()}</div>
      {/* <div className='col-4'>{renderPreviewMode()}</div> */}
    </div>
  )
}

export default FormBuilder
