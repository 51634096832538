import React, {useEffect} from 'react'

import {Field} from 'formik'
import {BookingTicketModel} from '../core/bookingsStore'
import clsx from 'clsx'

type Props = {
  bookingTicket: BookingTicketModel
  name: string
  onChange?: (qty) => void
}

const TicketToggler: React.FC<Props> = (props: Props) => {
  const {name, bookingTicket, onChange} = props
  const [disableMinus, setDisableMinus] = React.useState<boolean>(true)
  const [disablePlus, setDisablePlus] = React.useState<boolean>(false)

  useEffect(() => {
    setDisablePlus(bookingTicket.ticket.canBook.code === 'ticketSoldOut')
    setDisableMinus(bookingTicket.ticket.canBook.code === 'ticketSoldOut')

    // eslint-disable-next-line
  }, [])

  const handleQtyChange = (value) => {
    if (onChange) onChange(value)
  }

  return (
    <Field name={name}>
      {({field, form, meta}: any) => {
        // Your component logic goes here
        const updatedValue = field.value - bookingTicket.ticket.increment * 1 // Update the value of field

        const handleMinus = async () => {
          if (field.value > 0) {
            // add ticket qty
            await form.setFieldValue(name, updatedValue)

            setTimeout(() => form.setTouched(name, true), 1)

            setDisablePlus(false)
            if (field.value - 1 === 0) setDisableMinus(true)
          }
          handleQtyChange(field.value)
        }

        const handlePlus = async () => {
          const updatedValue = field.value + bookingTicket.ticket.increment * 1 // Update the value of field
          const availableTicket = bookingTicket.ticket.qty - bookingTicket.ticket.bookedTickets
          if (field.value < bookingTicket.ticket.maxQty || field.value <= availableTicket) {
            await form.setFieldValue(name, updatedValue)

            setDisableMinus(false)
            if (form.value === bookingTicket.ticket.maxQty || updatedValue >= availableTicket)
              setDisablePlus(true)
          }
          handleQtyChange(updatedValue)
        }

        // Your JSX goes here
        return (
          <div>
            <div className='d-flex align-items-center'>
              <button
                type='button'
                onClick={handleMinus}
                disabled={disableMinus}
                className={clsx('btn-circle btn btn-primary btn-border btn-icon fs-1', {
                  'btn-secondary text-dark': disableMinus,
                })}
              >
                -
              </button>
              <span className='min-w-50px text-center fs-3 fw-bolder text-darker'>
                {field.value}
              </span>
              <input type='hidden' name={`${name}`} />
              <button
                type='button'
                disabled={field.value === bookingTicket.ticket.maxQty || disablePlus}
                onClick={handlePlus}
                className={clsx('btn-circle btn btn-primary btn-border btn-icon fs-1', {
                  'btn-secondary text-dark':
                    field.value === bookingTicket.ticket.maxQty || disablePlus,
                })}
              >
                +
              </button>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

export default TicketToggler
