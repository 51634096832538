import React from 'react'
import {useField} from 'formik'

type SwitchInputProps = {
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SwitchInput: React.FC<SwitchInputProps> = ({name, ...props}) => {
  const [field, meta, helpers] = useField(name)
  return (
    <div className='form-check form-switch'>
      <input
        type='checkbox'
        id={name}
        className={`form-check-input ${meta.touched && meta.error ? 'is-invalid' : ''}`}
        {...field}
        {...props}
        checked={field.value}
        onChange={(e) => {
          helpers.setValue(e.target.checked)
          props.onChange && props.onChange(e)
        }}
      />

      {meta.touched && meta.error ? <div className='invalid-feedback'>{meta.error}</div> : null}
    </div>
  )
}

export default SwitchInput
