/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'
import {AuthPage, Logout} from '../modules/auth'
import {useAuthStore} from '../modules/auth/core/authStore'
import {PublicRoutes} from './PublicRoutes'
import {PatronRoutes} from './PatronRoutes'
import {usePatronStore} from '../modules/patrons/core/patronStore'
import HomePage from '../pages/home/HomePage'
import {useClientStore} from '../modules/clients/core/clientStore'
import {ClientRoutes} from './ClientRoutes'
import PublicForm from '../pages/forms/PublicForm'
import {GuestLayout} from '../../_metronic/layout/GuestLayout'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {isLoggedIn, selectedAccount} = useAuthStore()
  const {isPatronLoggedIn} = usePatronStore()
  const {isClientLoggedIn} = useClientStore()
  const loggedIn = isLoggedIn()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='event/*' element={<PublicRoutes />} />
          <Route path='f/*' element={<GuestLayout />}>
            <Route path=':formId' element={<PublicForm />} />
            <Route path=':formId/:formDataId' element={<PublicForm />} />
          </Route>

          <Route path='logout' element={<Logout />} />

          {/* USER LOGGED IN */}
          {loggedIn && selectedAccount ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              {/* patron is logged in and  */}
              {isPatronLoggedIn || isClientLoggedIn ? (
                <>
                  {isClientLoggedIn ? (
                    <>
                      <Route path='/*' element={<ClientRoutes />} />
                      <Route index element={<Navigate to='/client' />} />
                    </>
                  ) : (
                    <>
                      <Route path='/*' element={<PatronRoutes />} />
                      <Route index element={<Navigate to='/patron' />} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* if user logged in but account not selected:  go to the select account page */}
                  <Route path='auth/*' element={<AuthPage />} />
                  {loggedIn && !selectedAccount ? (
                    <Route path='*' element={<Navigate to='/auth/select-account' />} />
                  ) : (
                    <>
                      <Route path='*' element={<HomePage />} />
                      <Route path='*' element={<Navigate to='/auth' />} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
