import React, {useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import SubmitButton from '../../../../_components/SubmitButton'
import * as Yup from 'yup'
import {useEventsStore} from '../core/eventsStore'
import PhoneField from '../../../../_components/PhoneField'
import {KTIcon} from '../../../../_metronic/helpers'

// write validation schema with yup
const validationSchema = Yup.object().shape({
  organiserName: Yup.string().required('Organiser is required'),
  organiserEmail: Yup.string()
    .required("Organiser's email is required")
    .email('Enter a valid email address.'),
  organiserPhone: Yup.string(),
  organiserWebsite: Yup.string().url('Enter a valid URL'),

  organiserX: Yup.string(),
  organiserIG: Yup.string(),
  organiserFB: Yup.string(),
  organiserWhatsapp: Yup.string(),
  organiserYoutube: Yup.string().url('Enter a valid URL'),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const OrganiserDetails = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent} = useEventsStore()

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Event successfully updated')

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 p-5 p-md-10 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_organiser_details'
        aria-expanded='true'
        aria-controls='kt_organiser_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Organiser's Details</h3>
        </div>
      </div>

      <div id='kt_organiser_details' className='show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  <div className='card-body border-top p-5 p-md-10'>
                    <div className='fv-row'>
                      <div className='row'>
                        {/* notify user that this information will be made public */}
                        <div className='alert'>
                          <div className='alert-text'>
                            <KTIcon iconName='information-2' iconType='outline' className='me-1' />
                            The following information will be made public
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label required'>Contact Person</label>
                          <Field name='organiserName' className='form-control form-control-lg ' />
                          <span className='form-text text-muted ms-2 mt-1'>
                            Will appear in the event listing and search bar
                          </span>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserName' />
                          </div>
                        </div>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label required'>Contact Email</label>

                          <Field name='organiserEmail' className='form-control form-control-lg ' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserEmail' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='fv-row'>
                      <div className='row'>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label'>WhatsApp Number</label>

                          <PhoneField name='organiserWhatsapp' />
                          <div className='text-danger mt-2'>
                            {form.errors.organiserWhatsapp && (
                              <span>{form.errors.organiserWhatsapp}</span>
                            )}
                          </div>
                        </div>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label'>Phone Number</label>
                          <PhoneField name='organiserPhone' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserPhone' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='fv-row '>
                      <div className='row'>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label'>X Handle</label>
                          <Field name='organiserX' className='form-control form-control-lg ' />
                          <span className='form-text text-muted ms-2 mt-1'>
                            Only enter the handle. e.g. @skybookings
                          </span>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserX' />
                          </div>
                        </div>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label'>Instagram Handle</label>
                          <Field name='organiserIG' className='form-control form-control-lg ' />
                          <span className='form-text text-muted ms-2 mt-1'>
                            Only enter the handle. e.g. @skybookings
                          </span>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserIG' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='fv-row '>
                      <div className='row'>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label'>Website</label>

                          <Field
                            name='organiserWebsite'
                            className='form-control form-control-lg '
                          />
                          <span className='form-text text-muted ms-2 mt-1'>
                            Enter full url. e.g. https://skybookings.com
                          </span>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserWebsite' />
                          </div>
                        </div>
                        <div className='col-lg-6 mb-5'>
                          <label className='form-label'>YouTube Link</label>
                          <Field
                            name='organiserYoutube'
                            className='form-control form-control-lg '
                          />
                          <span className='form-text text-muted ms-2 mt-1'>
                            {' '}
                            Enter the full Channel URL
                          </span>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='organiserYoutube' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end p-5 p-md-10'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {OrganiserDetails}
