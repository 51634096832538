import {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {eventsStore} from '../events/core/eventsStore'
import {timeDifference} from '../../../_helpers/_helpers'
import {useBookingStore} from './core/bookingsStore'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {BookingTicketItem} from './SingleEventPartials/BookingTicketItem'

const PublicTicketPage: FC = () => {
  const {ticketId, eventId} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const {publicEvent, getPublicEvent} = eventsStore()
  const {currentBookingTicket, getBooking, getOneBookingTicket} = useBookingStore()

  useEffect(() => {
    if (eventId && ticketId) {
      setIsLoading(true)

      getPublicEvent(eventId)
      getOneBookingTicket(ticketId).then((bt: any) => {
        getBooking(bt.booking)
      })

      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    }

    // eslint-disable-next-line
  }, [eventId])

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <div className='container d-flex w-100 w-md-600px flex-column align-items-center justify-content-center'>
          <div className='d-flex mt-10 w-100 px-10 align-items-center'>
            <div className='d-flex py-3 align-items-sm-center'>
              {/* begin::Symbol */}
              <div className='me-5'>
                <div
                  style={{
                    backgroundImage: `url(${publicEvent.artwork})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'black',
                  }}
                  className='w-50px h-50px symbol symbol-50px'
                />
              </div>
              {/* end::Symbol */}
              {/* begin::Section */}

              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <Link
                    to={`/event/${publicEvent.id}`}
                    className='d-flex flex-grow-1 flex-row-fluid flex-wrap'
                  >
                    <span className='text-dark fw-bolder d-block fs-3'>{publicEvent.name}</span>
                  </Link>
                  <span className='text-muted fw-semibold d-block fs-7'>
                    Starts {timeDifference(new Date(publicEvent?.startDate))}
                  </span>
                </div>
              </div>

              {/* end::Section */}
            </div>
          </div>
          <div className='d-flex px-5 px-md-10 mb-10 w-100'>
            <BookingTicketItem
              key={ticketId}
              event={publicEvent}
              enableSharing={false}
              bookingTicket={currentBookingTicket}
              className='w-100'
              isPublic={true}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {PublicTicketPage}
