/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {EventModel, eventsStore} from '../core/eventsStore'
import clsx from 'clsx'
import {EventCard} from './EventCard'
import {EventOptionsModal} from './EventOptionsModal'
import {PageLoadingSpinner} from '../../../../_components/PageLoadingSpinner'
import {Link} from 'react-router-dom'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import {Pagination, useClientStore} from '../../clients/core/clientStore'
import {BreadcrumbModel, useAppSettingsStore} from '../../settings/core/appSettingsStore'

type Props = {
  className: string
}

// page data
const crumbs: BreadcrumbModel[] = [
  {
    object: 'Events',
    title: 'All',
    link: '/events',
  },
]

const EventsList: React.FC<Props> = ({className}) => {
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {isClientLoggedIn, currentClient} = useClientStore()
  const {getEvents, getMoreEvents, pagination, setQuery, currentEvent, query} = eventsStore()
  const {getClientEvents} = useClientStore()
  const [events, setEvents] = useState<EventModel[]>([])
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  // bookings
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showOptions] = useState<boolean>(false)

  useEffect(() => {
    setBreadcrumbs(crumbs)
    setCurrentPage('Events')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isClientLoggedIn && currentClient.id) {
      // get events based on client
      setIsLoading(true)
      const pg: Pagination = {page: 1, limit: 10, client: currentClient.id}
      getClientEvents(pg)
        .then((response) => {
          const cEvents = response?.map((ce) => ce.event)
          setEvents(cEvents)
        })
        .finally(() => setIsLoading(false))
    } else if (selectedAccountsUsers.account.id) {
      //  get events
      setIsLoading(true)
      setQuery({scope: 'future', page: 1})
      getEvents(selectedAccountsUsers.account.id)
        .then((response) => {
          // set events
          setEvents(response)
        })
        .finally(() => setIsLoading(false))
    }

    // eslint-disable-next-line
  }, [])

  const handleFutureEvents = async () => {
    setIsLoading(true)
    setQuery({scope: 'future', page: 1})
    setEvents(await getEvents(selectedAccountsUsers.account.id))
    setIsLoading(false)
  }

  const handlePastEvents = async () => {
    setIsLoading(true)
    setQuery({scope: 'past', page: 1})
    setEvents(await getEvents(selectedAccountsUsers.account.id))
    setIsLoading(false)
  }

  const handleLoadMore = async () => {
    setIsLoading(true)
    setEvents(await getMoreEvents(selectedAccountsUsers.account.id))
    setIsLoading(false)
  }

  return (
    <>
      {/* HEADER */}
      <div className='row mb-5 mb-lg-0'>
        <div className='d-flex align-items-center justify-content-between p-2 p-xl-5'>
          <div className='d-flex flex-column'>
            <span className='fs-3 fw-bold'>{events.length} Events</span>

            {!isClientLoggedIn && (
              <span className='fs-5 text-muted'>
                {events.length > 0
                  ? `Found ${pagination.totalResults} ${query.scope} events.`
                  : `no events found`}
              </span>
            )}
          </div>
          {!isClientLoggedIn && (
            <div className='btn-group '>
              <button
                type='button'
                onClick={handleFutureEvents}
                className={clsx('btn btn-light btn-active-secondary btn-outline', {
                  active: query.scope === 'future',
                })}
              >
                Future
              </button>
              <button
                type='button'
                onClick={handlePastEvents}
                className={clsx('btn btn-light btn-active-secondary btn-outline', {
                  active: query.scope !== 'future',
                })}
              >
                Past
              </button>
            </div>
          )}
        </div>
      </div>

      {/* EVENT ITEMS */}

      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {events.length === 0 ? (
            <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <div className='d-flex'>
                  <KTIcon
                    className='svg-icon-3x svg-icon-primary mb-5 '
                    iconName='flaticon-safebox-1'
                  />
                  <span className='fs-2x fw-bolder text-dark'>No Events yet. </span>
                </div>
                <div className='fs-5 text-muted text-center'>
                  Use the button below to add an event
                </div>
                <div className='d-flex flex-row p-5 '>
                  <Link to={'/events/create'} className='btn btn-info btn-sm'>
                    Create Event
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='row row-cols-xl-3 row-cols-lg-3 row-cols-12'>
                {events.map((event, index) => (
                  // set used colors
                  <EventCard key={index} className='col' event={event} />
                ))}

                {/* check whether to show a load more button */}
                {pagination.page < pagination.totalPages && (
                  <div className='p-2 p-xl-5 '>
                    <div
                      className={`d-flex min-h-200px align-items-center justify-content-center border-1 border-dashed rounded`}
                    >
                      <button onClick={handleLoadMore} className='btn btn-secondary' type='button'>
                        {isLoading ? (
                          <span
                            className='spinner-border spinner-border-sm me-1'
                            role='status'
                            aria-hidden='true'
                          ></span>
                        ) : (
                          <KTIcon iconName='right' className='fs-5 text-success' />
                        )}
                        Get More Events
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* EVENT OPTIONS MODAL */}
              <EventOptionsModal event={currentEvent} showModal={showOptions} />
            </>
          )}
        </>
      )}
    </>
  )
}

export {EventsList}
