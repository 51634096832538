import {createRef, FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import Papa from 'papaparse'
import toast, {Toaster} from 'react-hot-toast'
import {FieldArray, Form, Formik} from 'formik'
import {usePatronStore} from '../../../modules/patrons/core/patronStore'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {CreatePatronType} from '../../../modules/patrons/core/patronApi'
import Tippy from '@tippyjs/react'

type Props = {
  mode: 'button' | 'icon'
  buttonLabel?: string
  buttonClassName?: string
  onImported?: (data, importReport: {inserted: number; updated: number; failed: number}) => void
}

const defaultFields = {
  name: 'Name (*)',
  email: 'Email (*)',
  phone: 'Phone (*)',
  tags: 'Tags',
}

const PatronImporter: FC<Props> = (props) => {
  const {mode, buttonClassName = '', buttonLabel = 'Import Patrons', onImported} = props

  const [importing, setImporting] = useState(false)
  const [show, setShow] = useState(false)
  const [parsedData, setParsedData] = useState<string[]>([]) // State to store parsed data
  const [parsedFields, setParsedFields] = useState<string[]>([]) // State to store parsed fields
  const initialValues = {
    mappings: parsedFields.map(() => ''),
  }
  const [requiredFieldsAreSet, setRequiredFieldsAreSet] = useState(false)
  const fileUploadRef = createRef<HTMLInputElement>()
  const [progress, setProgress] = useState(0)
  const {postPatrons} = usePatronStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()

  useEffect(() => {
    reset()
    // eslint-disable-next-line
  }, [])

  const reset = () => {
    setParsedData([])
    setParsedFields([])
    setRequiredFieldsAreSet(false)
    if (fileUploadRef.current) fileUploadRef.current.value = ''
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    // check if file mime type is suitable for CSV
    if (file.type !== 'text/csv') {
      toast.error('Invalid file type. Please upload a CSV file')
      return
    }

    if (file) {
      Papa.parse(file, {
        header: true, // Set to false if you don't have headers in CSV
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data.length > 0) {
            setParsedData(results.data) // Store parsed data in state
            setParsedFields(results.meta.fields) // Store parsed fields in state
          } else {
            toast.error('Error parsing CSV')
          }
        },
        error: (error) => toast.error('There was a problem processing the CSV'),
      })
    }
  }

  const handleDropdownChange = (e, index, form) => {
    const selectedValue = e.target.value
    const newMappings = [...form.values.mappings]
    newMappings[index] = selectedValue
    form.setFieldValue('mappings', newMappings)

    // Check if required fields are set
    const requiredFields = ['name']
    const optionalFields = ['email', 'phone']
    const selectedFields = newMappings.filter((value) => value !== '')

    const hasRequiredFields = requiredFields.every((field) => selectedFields.includes(field))
    const hasOptionalField = optionalFields.some((field) => selectedFields.includes(field))

    if (!hasRequiredFields || !hasOptionalField) {
      setRequiredFieldsAreSet(false)
    } else {
      setRequiredFieldsAreSet(true)
    }
  }

  const handleSubmit = async (values, {setSubmitting}) => {
    try {
      setSubmitting(true)
      setImporting(true)

      // Re-package the mapped data to CreatePatronType
      const mappedData = parsedData.map((row) => {
        const patron: CreatePatronType = {name: '', account: selectedAccountsUsers.account.id}
        values.mappings.forEach((mapping, index) => {
          if (mapping) {
            patron[mapping] = row[parsedFields[index]]
          }
        })
        return patron
      })

      // Submit data to postPatrons function
      const response = await postPatrons(selectedAccountsUsers.account.id, mappedData, (pct) => {
        setProgress(pct)
      })

      if (onImported) onImported(mappedData, response)

      // Handle completion
      setShow(false)
      setImporting(false)
    } catch (error) {
      // Handle error
      toast.error('Failed to import patrons')
    } finally {
      setSubmitting(false)
      setImporting(false)
    }
  }

  return (
    <>
      <Toaster />

      {mode === 'button' && (
        <Tippy content='Import Patrons' placement='top'>
          <button className={buttonClassName} onClick={() => setShow(true)}>
            {buttonLabel}
          </button>
        </Tippy>
      )}
      {mode === 'icon' && (
        <Tippy content='Import Patrons' placement='top'>
          <button
            className={`btn btn-icon btn-outline btn-active-secondary me-2`}
            onClick={() => setShow(true)}
          >
            {importing ? (
              <span className='spinner-border spinner-border-sm me-2'></span>
            ) : (
              <KTIcon iconName='cloud-add' className='fs-2 text-dark p-0 ' iconType='outline' />
            )}
          </button>
        </Tippy>
      )}

      <Modal
        show={show}
        size='xl'
        centered
        onHide={() => {
          reset()
          setShow(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Patrons</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-10'>
          {/* STEP 1 */}
          <div className='d-flex align-items-center mb-10 justify-content-between w-100'>
            <div className='d-flex mb-5 me-10 w-50'>
              <div className='d-flex flex-column'>
                <span className='fs-5 fw-bolder'>1. Upload CSV File</span>
                <span className='text-muted fs-7'>
                  Choose a CSV file from your computer. For help creating a CSV file, Google 'how to
                  create a CSV from Excel' or the platform you are using.
                </span>
              </div>
            </div>
            <div className='d-flex flex-row position-relative'>
              <input
                ref={fileUploadRef}
                onChange={handleFileUpload}
                type='file'
                className='p-5 bg-light border border-secondary rounded'
              />

              {/* cancel button when there's a file */}
              {parsedData.length > 0 && (
                <button
                  className='btn btn-sm btn-light-danger btn-active-danger position-absolute top-0 end-0 mt-5 me-5'
                  onClick={() => {
                    setParsedData([])
                    setParsedFields([])
                    if (fileUploadRef.current) fileUploadRef.current.value = ''
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>

          {/* STEP 2 */}
          {parsedData.length > 0 && (
            <div className='d-flex align-items-center w-100 mb-10 justify-content-between w-100'>
              <div className='d-flex mb-5 me-10 w-100'>
                <div className='d-flex flex-column w-100'>
                  <span className='fs-5 fw-bolder'>2. Map Columns</span>
                  <span className='text-muted fs-7 mb-10 w-50'>
                    Here's a preview of your data. Map the columns to the correct fields in the
                    system. Required fields include Name, Email or Phone, i.e. either map email or
                    the phone field.
                  </span>

                  {/* CREATE TABLE REP OF COLUMNS */}
                  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {(form) => (
                      <Form>
                        <div className='d-flex flex-column w-100' style={{overflowX: 'auto'}}>
                          <div
                            className='table-responsive p-5 rounded border border-secondary bg-light'
                            style={{
                              inset: '0px',
                              overflowX: 'auto',
                              overflowY: 'auto',
                              boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)',
                            }}
                          >
                            <table className='table table-striped table-bordered bg-white table-hover table-checkable table-sm'>
                              <thead>
                                <tr>
                                  {parsedFields.map((field, index) => (
                                    <th className='fixed-width' key={index}>
                                      {field}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name='mappings'>
                                  {() => (
                                    <>
                                      <tr>
                                        {parsedFields.map((field, index) => (
                                          <td className='fixed-width' key={index}>
                                            <select
                                              className='form-select form-select-sm'
                                              value={form.values.mappings[index]}
                                              onChange={(e) => handleDropdownChange(e, index, form)}
                                            >
                                              <option value=''>Select</option>
                                              {Object.keys(defaultFields)
                                                .sort((a, b) => (a > b ? 1 : -1))
                                                .map((key) => (
                                                  <option
                                                    key={key}
                                                    value={key}
                                                    disabled={
                                                      form.values.mappings.includes(key) &&
                                                      form.values.mappings[index] !== key
                                                    }
                                                  >
                                                    {defaultFields[key]}
                                                  </option>
                                                ))}
                                            </select>
                                          </td>
                                        ))}
                                      </tr>
                                      {parsedData.slice(0, 3).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                          {parsedFields.map((field, fieldIndex) => (
                                            <td
                                              className='fixed-width'
                                              key={fieldIndex}
                                              style={{minWidth: '225px', maxWidth: '225px'}}
                                            >
                                              {row[field]}
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                    </>
                                  )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>

                          {/* STEP 3 */}
                          {requiredFieldsAreSet && (
                            <div className='d-flex align-items-center my-10 justify-content-between w-100'>
                              <div className='d-flex mb-5 me-10 w-50'>
                                <div className='d-flex flex-column'>
                                  <span className='fs-5 fw-bolder'>3. Upload Data</span>
                                  <span className='text-muted fs-7'>
                                    You are about to upload {parsedData.length} patrons. Please note
                                    that duplicate contacts will be ignored. Click 'Import' to
                                    proceed.
                                  </span>
                                </div>
                              </div>
                              <div className='d-flex flex-column position-relative'>
                                <button
                                  type='submit'
                                  className='btn btn-outline btn-lg btn-active-dark'
                                >
                                  {importing ? <>{`${progress}%`}</> : 'Start Importing'}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PatronImporter
