import axios from 'axios'
export const FORMS_ENDPOINT = '/forms'

export function _postForm(body: any) {
  return axios.post(`${FORMS_ENDPOINT}/`, body)
}

export function _patchForm(id, body: any) {
  return axios.patch(`${FORMS_ENDPOINT}/${id}/`, body)
}

export function _deleteForm(id) {
  return axios.delete(`${FORMS_ENDPOINT}/${id}/`)
}

export function _getForms(query: any) {
  return axios.get(`${FORMS_ENDPOINT}/`, {params: {...query}})
}

export function _getForm(id: string) {
  return axios.get(`${FORMS_ENDPOINT}/${id}/`)
}

export function _getFormByHandle(handle: string) {
  return axios.get(`${FORMS_ENDPOINT}/handle/${handle}/`)
}

export function _getFormPublic(id: string) {
  return axios.get(`${FORMS_ENDPOINT}/${id}/public`)
}

// FORM DATA

export function _postFormData(formId: string, body: any) {
  return axios.post(`${FORMS_ENDPOINT}/${formId}/data/`, body)
}

export function _patchFormData(formId: string, dataId: string, body: any) {
  return axios.patch(`${FORMS_ENDPOINT}/${formId}/data/${dataId}/`, body)
}

export function _deleteFormData(formId: string, dataId: string) {
  return axios.delete(`${FORMS_ENDPOINT}/${formId}/data/${dataId}/`)
}

export function _getFormData(formId: string, dataId: string) {
  return axios.get(`${FORMS_ENDPOINT}/${formId}/data/${dataId}/`)
}

export function _queryFormData(formId: string, query: any) {
  return axios.get(`${FORMS_ENDPOINT}/${formId}/data/`, {params: {...query}})
}

export function _exportFormData(formId: string, query: any) {
  return axios.get(`${FORMS_ENDPOINT}/${formId}/data/export/`, {params: {...query}})
}
