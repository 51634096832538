/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {EngageWidget10} from '../../../_metronic/partials/widgets'

import {useDashboardsStore} from './core/dashboardStore'
import {EventsBookingsCard} from './partials/EventsBookingsCard'

import {WalletCard} from './partials/WalletCard'
import {QuickLinks} from './partials/QuickLinks'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {SalesOrBookingsCard} from './partials/SalesOrBookingsCard'
import {AdminWalletCard} from './partials/AdminWalletCard'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {useAuthStore} from '../../modules/auth/core/authStore'
import {useNavigate} from 'react-router-dom'
import {useAppSettingsStore} from '../../modules/settings/core/appSettingsStore'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {KTIcon} from '../../../_metronic/helpers'
import {formatMoney, formatNumber} from '../../../_helpers/_helpers'
import Tippy from '@tippyjs/react'

const DashboardPage: FC = () => {
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {getDashboardData} = useDashboardsStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()
  const {redirect, retrieveAuth, unsetRedirect} = useAuthStore()
  const navigate = useNavigate()

  // check if there is a redirect
  useEffect(() => {
    if (redirect) {
      retrieveAuth()
      unsetRedirect()
      navigate(redirect)
    }

    // eslint-disable-next-line
  }, [redirect])

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    // set current page
    setBreadcrumbs([])

    // get current user name
    setCurrentPage(selectedAccountsUsers.account.name)

    // get dashboard
    getDashboardData(selectedAccountsUsers.account.id).finally(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountsUsers.account.id])

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <EventsBookingsCard className='h-md-50 mb-5 mb-xl-10' description='Active Events' />
              <WalletCard className='h-md-50 mb-5 mb-xl-10' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <SalesOrBookingsCard
                chartColor='black'
                chartType='auto'
                className='h-lg-50 mb-5 mb-xl-10'
              />
              <QuickLinks className='h-lg-50 bg-gray-200' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-12 col-lg-6 col-xxl-6'>
              <EngageWidget10 className='h-md-100' />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

const AdminDashboardPage: FC = () => {
  const {dashboardData, getAdminDashboardData} = useDashboardsStore()
  const {selectedAccountsUsers, getCurrentAccount} = useAccountsUsersStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // get dashboard
    getAdminDashboardData().finally(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {/* show KPIs */}
          {isMobileDevice() && getCurrentAccount()?.plan === 'skybookings' && (
            <div className='d-flex align-items-center flex-wrap me-1'>
              {/* upcoming events */}
              <div className='d-flex align-items-center p-2  bg-gray-200 rounded me-3 mb-3'>
                <KTIcon iconName='calendar' iconType='outline' className='fs-5 text-dark me-2' />
                <div className='fs-7 fw-bold text-dark me-2'>
                  {formatNumber(dashboardData?.adminKpis?.totalUpcomingEvents, 0, false)}
                </div>
                <div className='fs-7 fw-bold text-muted'>Upcoming</div>
              </div>

              {/* events */}
              <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3 mb-3'>
                <KTIcon iconName='calendar-8' iconType='outline' className='fs-5 text-dark me-2' />
                <div className='fs-7 fw-bold text-dark me-2'>
                  {formatNumber(dashboardData?.adminKpis?.totalEvents, 0, false)}
                </div>
                <div className='fs-7 fw-bold text-muted'>Events</div>
              </div>

              {/* tickets */}
              <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3 mb-3'>
                <KTIcon iconName='tag' iconType='outline' className='fs-5 text-dark me-2' />
                <div className='fs-7 fw-bold text-dark me-2'>
                  {formatNumber(dashboardData?.adminKpis?.totalTickets, 0, true)}
                </div>
                <div className='fs-7 fw-bold text-muted me-2'>Tickets</div>
                <div className=' fs-7 fw-bold text-dark'>
                  {formatMoney(
                    dashboardData?.adminKpis?.totalSales,
                    selectedAccountsUsers.account.currency,
                    0,
                    true
                  )}
                </div>
              </div>

              {/* payouts */}
              <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3 mb-3'>
                <div className=' fs-7 fw-bold text-dark me-2'>
                  {formatNumber(dashboardData?.adminKpis?.totalPayouts, 0, true)}
                </div>
                <div className='fs-7 fw-bold text-muted me-2'>Payouts</div>
                <div className=' fs-7 fw-bold text-dark'>
                  {formatMoney(
                    dashboardData?.adminKpis?.totalPayoutsValue,
                    selectedAccountsUsers.account.currency,
                    0,
                    true
                  )}
                </div>
              </div>

              {/* users */}
              <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3 mb-3'>
                <KTIcon iconName='user' iconType='outline' className='fs-5 text-dark me-2' />
                <div className=' fs-7 fw-bold text-dark me-2'>
                  {formatNumber(
                    dashboardData?.adminKpis?.totalUsers,

                    0,
                    true
                  )}
                </div>
                <div className='fs-7 fw-bold text-muted'>Users</div>
              </div>

              {/* accounts */}
              <div className='d-flex align-items-center p-2 bg-gray-200 rounded me-3 mb-3'>
                <KTIcon iconName='user' iconType='outline' className='fs-5 text-dark me-2' />
                <div className=' fs-7 fw-bold text-dark me-2'>
                  {formatNumber(dashboardData?.adminKpis?.totalUsers, 0, true)}
                </div>
                <div className='fs-7 fw-bold text-muted'>Accounts</div>
              </div>

              {/* patrons */}
              <Tippy
                placement='bottom'
                content={`${formatNumber(
                  dashboardData?.adminKpis?.totalPatrons,
                  0,
                  false
                )} Patrons`}
              >
                <div className='cursor-pointer d-flex align-items-center p-2 bg-gray-200 rounded me-3 mb-3'>
                  <KTIcon iconName='users' iconType='outline' className='fs-5 text-dark me-2' />
                  <div className='fs-7 fw-bold text-dark me-2'>
                    {formatNumber(dashboardData?.adminKpis?.totalPatrons, 0, true)}
                  </div>
                  <div className='fs-7 fw-bold text-muted'>Patrons</div>
                </div>
              </Tippy>
            </div>
          )}{' '}
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <EventsBookingsCard
                className='h-md-50 mb-5 mb-xl-10'
                description='Active Events'
                linkTo='/admin/accounts'
              />
              <AdminWalletCard className='h-md-50 mb-5 mb-xl-10 bg-gray-200 ' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <SalesOrBookingsCard
                chartColor='black'
                chartType='bookings'
                className='h-lg-50 mb-5 mb-xl-10'
              />
              <SalesOrBookingsCard
                chartColor='black'
                chartType='sales'
                className='h-lg-50 mb-5 mb-xl-10'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-12 col-lg-6 col-xxl-6'>
              <EngageWidget10 className='h-md-100' />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {getCurrentAccount} = useAccountsUsersStore()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {getCurrentAccount()?.plan !== 'skybookings' ? <DashboardPage /> : <AdminDashboardPage />}
    </>
  )
}

export {DashboardWrapper}
