import {FC, useEffect} from 'react'
import SearchBar from './partial/SearchBar'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {initializeGA, trackPageView} from '../../../_helpers/_analytics'
import {appSettingsStore} from '../../modules/settings/core/appSettingsStore'

const HomePage: FC = () => {
  const {appSettings} = appSettingsStore()
  useEffect(() => {
    initializeGA()
    trackPageView(window.location.pathname)
  }, [])

  const ogData = {
    title: "skybookings.com | Ticketing for SA's most beautiful events",
    description: 'skybookings.com offers a premium ',
    url: 'https://skybookings.com',
    image: 'https://skybookings.com/og-image.png',
    siteName: 'skybookings.com',
    type: 'website',
  }

  return (
    <>
      {/* SEO STUFF */}
      <Helmet>
        <title>skybookings.com | ticketing for SA's most beautiful events</title>
        <meta name='description' content={ogData.description} />
        <link rel='canonical' href={ogData.url} />
        <meta property='og:title' content={ogData.title} />
        <meta property='og:description' content={ogData.description} />
        <meta property='og:url' content={ogData.url} />
        <meta property='og:image' content={ogData.image} />
        <meta property='og:site_name' content={ogData.siteName} />
        <meta property='og:type' content={ogData.type} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={ogData.title} />
        <meta name='twitter:description' content={ogData.description} />
        <meta name='twitter:image' content={ogData.image} />
      </Helmet>

      <div className='d-flex p-5 justify-content-end'>
        {/* login menu */}

        <Link to='/auth' className='text-gray-600 text-hover-primary me-3'>
          Login
        </Link>
        <Link to='/auth/registration' className='text-gray-600 text-hover-primary me-3'>
          Sign up
        </Link>
      </div>
      <div className='d-flex flex-grow-1 p-20 vh-100 justify-content-center align-items-center h-100'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 mb-10'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.png')} className='h-30px' />
          </div>
          <div className='d-flex flex-column align-items-center'>
            <SearchBar />

            <div className='d-flex mt-10 me-1'>
              <Link
                to='/auth/patron'
                className='me-5 text-hover-dark fw-semibold'
                style={{color: '#919191'}}
              >
                Get My Tickets
              </Link>
              <a
                href={`https://info.skybookings.com`}
                rel='noreferrer'
                target='_blank'
                className='text-hover-dark fw-semibold me-5'
                style={{color: '#919191'}}
              >
                About Us
              </a>
              <a
                href={`https://wa.me/${appSettings?.sbPhone}`}
                rel='noreferrer'
                target='_blank'
                className='text-hover-dark fw-semibold'
                style={{color: '#919191'}}
              >
                Help
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex p-5'>
        {/* version  */}
        <div className='text-dark fw-bold text-hover-primary'>{process.env.REACT_APP_VERSION}</div>
      </div>
    </>
  )
}

export default HomePage
