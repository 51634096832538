import {useEffect, useState} from 'react'
import {Toaster} from 'react-hot-toast'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {capitaliseFirstLetter} from '../../../_helpers/_helpers'
import {useMessageStore} from './core/messagesStore'
import {KTIcon} from '../../../_metronic/helpers'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {MessagesTable} from './MessagesTable'
import {Link} from 'react-router-dom'
import {BreadcrumbModel, useAppSettingsStore} from '../../modules/settings/core/appSettingsStore'
import {PaginationControl} from 'react-bootstrap-pagination-control'

const MessagesPage = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const {pagination, messages, getMessages} = useMessageStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return
    setPageLoading(true)
    getMessages({...pagination, limit: 15, account: selectedAccountsUsers.account.id}).finally(
      () => {
        setPageLoading(false)
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountsUsers.account])

  // SETUP BREADCRUMBS
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Messages',
        title: 'All',
        link: '/messages',
      },
    ]

    setBreadcrumbs(crumbs)
    setCurrentPage('Messages')

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {/* PRELIMINARIES */}
      <Toaster />
      {pageLoading && <PageLoadingSpinner />}

      {/* HEADER SECTION */}
      <div className='row'>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between mb-10'>
            {/* COUNT */}
            <div className='d-flex flex-column'>
              <span className='fs-5 fw-bold'>
                {pagination.totalResults} {capitaliseFirstLetter(pagination.status ?? '')} Messages
              </span>
              <span className='fs-7 text-muted'>{`Viewing ${messages.length} on page ${pagination.page} of ${pagination.totalPages}`}</span>
            </div>

            {/* ACTION BUTTONS */}
            <div className='d-flex flex-rows align-items-center'>
              {/* export button */}
              <Link className='btn btn-sm btn-outline btn-active-secondary' to={`/messages/create`}>
                Create Message
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* MESSAGES */}
      <MessagesTable />

      {/* NO MESSAGES */}
      {messages.length === 0 && !pageLoading && (
        <div className='d-flex flex-column align-items-center justify-content-center w-100 mt-10 border border-dashed border-gray-300 bg-light rounded p-10'>
          <KTIcon iconName='inbox' className='fs-2x text-muted' />
          <span className='fs-5 text-muted'>
            No messages found yet. But no problem, create a message by clicking the button below
          </span>
          <Link className='btn btn-sm btn-info btn-active-secondary mt-5' to={`/messages/create`}>
            Create Message
          </Link>
        </div>
      )}

      {/* PAGINATION */}
      {pagination.totalPages > 1 && (
        <div className='w-100 mt-10 d-flex justify-content-center'>
          <PaginationControl
            page={pagination.page}
            total={pagination.totalResults}
            limit={pagination.limit}
            changePage={(page) =>
              getMessages({...pagination, account: selectedAccountsUsers.account.id, page})
            }
          />
        </div>
      )}
    </>
  )
}
export default MessagesPage
