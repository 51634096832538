import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {usePatronStore} from '../../../modules/patrons/core/patronStore'
import {EventModel, useEventsStore} from '../../../modules/events/core/eventsStore'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {Typeahead} from 'react-bootstrap-typeahead'
import clsx from 'clsx'

import {MessageModel, RecipientValueModel} from '../core/messagesStore'
import {useFormikContext} from 'formik'
import {eventStatusLabel} from '../../../../_helpers/_templateHelpers'

type Props = {
  onSave: (recipients: RecipientValueModel) => void
  disabled?: boolean
}

const MessageEditorEvent: FC<Props> = ({disabled, onSave}) => {
  const [show, setShow] = useState(false)

  usePatronStore()
  const {events, getEvents, setQuery} = useEventsStore()
  const [selectedEvent, setSelectedEvent] = useState<EventModel>()
  const {selectedAccountsUsers} = useAccountsUsersStore()

  const form = useFormikContext<MessageModel>()

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    // setup event query
    setQuery({
      scope: 'all',
      limit: 9999999,
      sortBy: 'name:asc',
    })

    getEvents(selectedAccountsUsers.account.id)

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account])

  useEffect(() => {
    // get events
    if (events.length === 0 || !form.values.id) return

    if (events.length === 0) {
      setSelectedEvent(undefined)
      form.setFieldValue('event', null)
    } else {
      const event = events.find((event) => event.id === form.values.event)
      setSelectedEvent(event)
    }

    // eslint-disable-next-line
  }, [form.values, events])

  const handleSelectedEvent = (selected: any[]) => {
    if (selected && selected.length === 0) {
      form.setFieldValue('event', null)
      setSelectedEvent(undefined)
    } else {
      form.setFieldValue('event', selected[0].id)
      setSelectedEvent(selected[0])
      // unset enabled tickets
      form.setFieldValue('enabledTickets', [])
      form.setFieldValue('disabledTickets', [])

      if (!form.values.content) {
        form.setFieldValue('content', selected[0].content)
      }
    }
  }

  const handleSaveAndExit = () => {
    form.submitForm()
    setShow(false)
  }

  // GET ALL TICKETS HELPER
  const getAllTickets = () => (selectedEvent ? selectedEvent.tickets : [])

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button
          disabled={disabled}
          type='button'
          onClick={() => setShow(true)}
          className='btn btn-outline btn-active-dark text-nowrap'
        >
          Set Event Options
        </button>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className='modal-dialog-centered modal-dialog-scrollable'
      >
        <Modal.Header>
          <h5 className='modal-title'>Edit Event</h5>
        </Modal.Header>
        <Modal.Body>
          <div
            className={clsx('fw-row mb-10', {
              'h-250px': !selectedEvent,
            })}
          >
            <div className='col'>
              <div className='form-group'>
                <label className='form-label fw-bold'>Select Linked Event</label>
                <div className='text-muted small mb-5'>
                  Select an event to link to this message.
                </div>
                <Typeahead
                  id='events-to-link'
                  onChange={handleSelectedEvent}
                  className='w-100'
                  selected={events.filter((event) => event.id === form.values.event)}
                  labelKey={'name'}
                  placeholder='Select an event...'
                  allowNew={false}
                  newSelectionPrefix='Create a new event: '
                  emptyLabel='No events found'
                  options={events}
                />

                {/* event status */}
                {selectedEvent && (
                  <div className='small mt-5 bg-light-warning p-3 rounded text-dark'>
                    <span className='fw-bold'>{`${selectedEvent.name} status: `}</span>
                    {eventStatusLabel(selectedEvent)}
                    {!selectedEvent.canBook.canBook &&
                      'Please note that ticket rules are disabled since the event is not bookable.'}
                  </div>
                )}
              </div>
            </div>
          </div>

          {selectedEvent && selectedEvent?.canBook.canBook && (
            <>
              {/* visible tickets: these tickets will be available on the booking form */}
              <div className='fw-row mb-10'>
                <div className='col'>
                  <div className='form-group'>
                    <label className='form-label fw-bold'>Visible Tickets </label>
                    <div className='text-muted small mb-5'>
                      Choose tickets you want to be visible on the booking form.
                    </div>
                    <Typeahead
                      id='tickets-to-enable'
                      multiple
                      selected={
                        form.values.enabledTickets.map((tString) =>
                          getAllTickets().find((t) => t.id === tString)
                        ) as any
                      }
                      onChange={(selected) =>
                        form.setFieldValue(
                          'enabledTickets',
                          selected.map((s: any) => s.id)
                        )
                      }
                      labelKey={(option: any) => option.name}
                      options={selectedEvent.tickets}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={clsx('d-flex justify-content-end')}>
            <button className='btn btn-sm me-2 btn-active-secondary' onClick={() => setShow(false)}>
              Cancel
            </button>
            <button className='btn btn-sm btn-outline btn-active-dark' onClick={handleSaveAndExit}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MessageEditorEvent
