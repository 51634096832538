import {useBookingStore} from '../core/bookingsStore'
import {Link} from 'react-router-dom'

import {
  capitaliseFirstLetter,
  formatDateDMYHI,
  formatNumber,
  formatPhoneNumber,
  getCurrencySymbol,
} from '../../../../_helpers/_helpers'
import {useEventsStore} from '../../events/core/eventsStore'
import clsx from 'clsx'
import {bookingStatusLabel} from '../../../../_helpers/_templateHelpers'
import Tippy from '@tippyjs/react'

const BookingsTable = () => {
  const {bookings} = useBookingStore()
  const {currentEvent} = useEventsStore()

  return (
    <>
      {/* begin::Table */}
      <div className='table-responsive'>
        <div className='text-gray-600 fw-bold'>
          {/* end::Table head */}
          {/* begin::Table body */}
          <div className='d-flex flex-column'>
            {bookings.length > 0 &&
              bookings.map((booking) => {
                const tiv = (booking.stats && booking.stats.ticketsValue) || 0
                const trv = (booking.stats && booking.stats.transactionsValue) || 0

                const isPaid = tiv > trv ? true : false

                return (
                  <Link
                    key={booking.id}
                    to={`/bookings/${booking.id}/`}
                    className='bg-hover-light px-0 border-1 border-bottom  d-flex py-5'
                  >
                    {/* START:: SOMETHING */}
                    <div className='bg-hover d-flex text-start p-0 flex-column flex-grow-1'>
                      <div className='mb-2 d-flex'>
                        <div className='symbol symbol-50px me-5'>
                          {/* first letter */}
                          <span
                            className={clsx(`symbol-label text-white fw-bolder`, {
                              'bg-success': booking.status === 'complete',
                              'bg-danger': booking.status === 'cancelled',
                              'bg-warning': booking.status === 'pending',
                              'bg-secondary': booking.status === 'abandoned',
                            })}
                          >
                            {booking.patron.name ? booking.patron.name.charAt(0) : 'A'}
                          </span>
                        </div>

                        <div className='d-flex flex-column min-w-350px justify-content-start'>
                          <div className='d-flex align-items-start'>
                            <span className='text-dark fw-bold text-hover-primary mb-1 fs-4 me-3'>
                              {booking.patron.name}
                              <span
                                className={clsx('badge ms-2', {
                                  'badge-light-success': booking.status === 'complete',
                                  'badge-light-danger': booking.status === 'cancelled',
                                  'badge-light-warning': booking.status === 'pending',
                                  'badge-secondary': booking.status === 'abandoned',
                                  'badge-light-info': booking.status === 'checked',
                                })}
                              >
                                {capitaliseFirstLetter(booking.status)}
                              </span>
                            </span>
                          </div>
                          <Tippy content={formatDateDMYHI(booking.dateCreated.toString())}>
                            <span
                              className='text-start fw-normal text-muted'
                              dangerouslySetInnerHTML={{__html: bookingStatusLabel(booking)}}
                            />
                          </Tippy>
                        </div>

                        {/* CONTACT DETAILS */}
                        <div className='d-flex flex-column min-w-250px justify-content-start'>
                          <div className='d-flex align-items-start'>
                            <span className='fw-normal mb-1 fs-5 me-3'>
                              <div
                                onClick={(e) => {
                                  e.preventDefault()
                                  window.open(
                                    `mailto:${booking.patron.email}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                  )
                                }}
                                className='text-dark text-hover-primary'
                              >
                                {booking.patron.email}
                              </div>
                            </span>
                          </div>
                          {/* phone */}
                          <span className='text-start fw-normal text-muted'>
                            {booking.patron.phone &&
                              formatPhoneNumber(booking.patron.phone?.toString())}
                          </span>
                        </div>

                        {/* TICKETS */}
                        <div className='d-flex min-w-100px justify-content-start'>
                          <span className='text-muted fw-normal mb-2'>{`${booking.stats.ticketsCount} tickets`}</span>
                        </div>

                        {/*  */}
                      </div>
                    </div>
                    {/* END:: STATUS */}

                    {/* START:: VALUE */}

                    {currentEvent.sellTickets && (
                      <>
                        <div className='text-end d-flex flex-column min-w-100px flex-grow-1 '>
                          <span className='fs-6 text-dark fw-semifolder mb-2'>
                            {getCurrencySymbol(currentEvent.currency) +
                              ' ' +
                              formatNumber(booking.stats.ticketsValue)}
                          </span>
                          {/* paid */}
                          {isPaid ? (
                            <span className='text-mute fw-normal fs-7 d-block'>
                              {' Paid: '}
                              {getCurrencySymbol(currentEvent.currency) +
                                ' ' +
                                formatNumber(booking.stats.transactionsValue)}
                            </span>
                          ) : (
                            <span className='text-mute fw-normal fs-7 d-block'>Paid in full</span>
                          )}
                        </div>
                      </>
                    )}

                    {/* END:: VALUE */}
                  </Link>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export {BookingsTable}
