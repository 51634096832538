import clsx from 'clsx'
import {KTIcon} from '../../../../_metronic/helpers'
import {useFormStore} from '../core/formStore'
import React from 'react'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  toggleBtnIcon?: string
  toggleBtnLabel?: string
  menuPlacement?: string
  menuTrigger?: string
  onSwitchStatus?: (status: string) => void
  isLoading?: boolean
}

const FormStatusSwitcher = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-3',
  toggleBtnIcon = 'setting-3',
  toggleBtnLabel = 'Status',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
  onSwitchStatus,
  isLoading = false,
}: Props) => {
  const {currentForm} = useFormStore()

  const switchStatus = (status: string) => {
    onSwitchStatus && onSwitchStatus(status)
  }

  return (
    <>
      {/* begin::Menu toggle */}
      <div
        className={clsx('btn', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <div className='d-flex align-items-center'>
          {isLoading ? (
            <span className='spinner-border spinner-border-sm align-middle me-2' />
          ) : (
            <span
              className={clsx(`rounded rounded-circle p-0 me-2 w-10px h-10px`, {
                'bg-secondary': currentForm.status === 'draft',
                'bg-success': currentForm.status === 'active',
                'bg-warning': currentForm.status === 'paused',
              })}
            ></span>
          )}

          {toggleBtnLabel}
        </div>
      </div>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {currentForm.stats.responses === 0 && (
          <div className='menu-item px-3 my-0'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: currentForm.status === 'draft'})}
              onClick={() => switchStatus('draft')}
            >
              <span
                className='menu-icon symbol w-25px h-25px border-1 border-secondary'
                data-kt-element='icon'
                style={{border: '1px solid', borderRadius: '50%'}}
              >
                <KTIcon
                  iconName={currentForm.status === 'draft' ? 'check' : ''}
                  className='fs-1 circle symbol border-2 border-dark'
                />
              </span>
              <span className='menu-title'>Draft</span>
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: currentForm.status === 'active'})}
            onClick={() => switchStatus('active')}
          >
            <span
              className='menu-icon symbol w-25px h-25px border-1 border-secondary'
              data-kt-element='icon'
              style={{border: '1px solid', borderRadius: '50%'}}
            >
              <KTIcon
                iconName={currentForm.status === 'active' ? 'check' : ''}
                className='fs-1 circle symbol border-2 border-dark'
              />
            </span>
            <span className='menu-title'>Active</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: currentForm.status === 'paused'})}
            onClick={() => switchStatus('paused')}
          >
            <span
              className='menu-icon symbol w-25px h-25px border-1 border-secondary'
              data-kt-element='icon'
              style={{border: '1px solid', borderRadius: '50%'}}
            >
              <KTIcon
                iconName={currentForm.status === 'paused' ? 'check' : ''}
                className='fs-1 circle symbol border-2 border-dark'
              />
            </span>
            <span className='menu-title'>Paused</span>
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {FormStatusSwitcher}
