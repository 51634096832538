/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {useAppSettingsStore} from '../../../../app/modules/settings/core/appSettingsStore'

const Footer = () => {
  const {config} = useLayout()
  const {appSettings} = useAppSettingsStore()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a
          href='https://info.skybookings.com/'
          target='_blank'
          className='text-gray-800 text-hover-primary'
        >
          skybookings
        </a>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a
            href='https://info.skybookings.com/service-level-policy/'
            target='_blank'
            className='menu-link px-2'
          >
            Terms of Use
          </a>
        </li>

        <li className='menu-item'>
          <a
            href='https://info.skybookings.com/privacy-policy-2/'
            target='_blank'
            className='menu-link px-2'
          >
            Privacy Policy
          </a>
        </li>

        <li className='menu-item'>
          <a href='https://support.skybookings.com/' target='_blank' className='menu-link px-2'>
            Support
          </a>
        </li>

        <li className='menu-item'>
          <a href='https://support.skybookings.com' target='_blank' className='menu-link px-2'>
            {appSettings.version}
          </a>
        </li>
      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
