import {BookingModel} from '../app/modules/bookings/core/bookingsStore'
import {EventModel} from '../app/modules/events/core/eventsStore'
import {FormModel} from '../app/pages/forms/core/formStore'
import {timeDifference, formatNumber, getCurrencySymbol} from './_helpers'

export function eventDateLabel(startDate: string, endDate: string) {
  if (!startDate && !endDate) return ''

  if (new Date(endDate) < new Date()) return `Ended ${timeDifference(new Date(endDate))}`

  if (new Date(startDate) < new Date() && new Date(endDate) > new Date())
    return `<span class=''>Started.</span> Ends ${timeDifference(new Date(endDate))}`

  if (new Date(startDate) > new Date()) return `Starts ${timeDifference(new Date(startDate))}`

  return ''
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function eventStatusLabel(event: EventModel) {
  // summary label
  let label = `Event is ${event.canBook.canBook ? 'available' : 'not available'}. `

  if (event.canBook.canBook) {
    // check if event has started
    if (new Date(event.startDate) < new Date() && new Date(event.endDate) > new Date()) {
      label += `Started. Ends ${timeDifference(new Date(event.endDate))}. `
    } else {
      label += `Starts ${timeDifference(new Date(event.startDate))}. `
    }

    // total bookings
    if (event.stats.bookingsCount > 0 && !event.sellTickets)
      label += `${event.stats.bookingsCount} bookings so far. `

    // if event sells tickets get the total sales amount
    if (event.sellTickets) {
      if (event.stats.transactionsAmount > 0) {
        const currency = getCurrencySymbol(event.currency || 'ZAR')
        const val = formatNumber(event.stats.bookingsValue, 0)
        label += `Total sales: ${currency} ${val} from ${event.stats.bookingsCount} bookings.`
      } else {
        label += 'No sales yet. '
      }
    }
  } else {
    label += `${event.canBook.reason}. `
  }
  return label
}

export function formStatusLabel(form: FormModel) {
  // summary label
  let label = `Form is ${capitalizeFirstLetter(form.status)}. `

  // total bookings
  label +=
    form.stats.responses > 0 ? `${form.stats.responses} responses so far. ` : 'no responses yet.'

  return label
}

export function bookingStatusLabel(booking: BookingModel) {
  // summary label
  const currency = getCurrencySymbol(booking.event.currency || 'ZAR')
  let label = `Booked ` + timeDifference(new Date(booking.dateCreated.toString())) + `. `

  label += `${(booking && booking.stats && booking.stats.ticketsCount) || 0} tickets`

  if (booking.event?.sellTickets && booking.value > 0) {
    const val = formatNumber(booking.value, 0)
    label += ` valued at ${currency}${val}. `

    if (
      (booking.value > (booking && booking.stats && booking.stats.ticketsCount) || 0) &&
      booking.value - booking.stats.transactionsValue > 0
    ) {
      const pendingPaymentVal = formatNumber(booking.value - booking.stats.transactionsValue, 0)

      label += `Pending payment of ${currency}${pendingPaymentVal}. `
    } else {
      label += `Fully Paid. `
    }
  } else {
    label += '.'
  }

  return label
}
