import axios from 'axios'

export const BOOKINGS_ENDPOINT = `/bookings`

export type PostBookingType = {
  event: string
  patron: string
  status?: string
}
export function _postBookings(args: PostBookingType) {
  return axios.post(`${BOOKINGS_ENDPOINT}/`, args)
}

export type UpdateBookingType = {
  status: string
}
export function _patchBookings(id, args: UpdateBookingType) {
  return axios.patch(`${BOOKINGS_ENDPOINT}/${id}/`, args)
}

export function _deleteBookings(id, args: any) {
  return axios.delete(`${BOOKINGS_ENDPOINT}/${id}/`)
}

export function _getBookings(query: any) {
  return axios.get(`${BOOKINGS_ENDPOINT}`, {params: {...query}})
}

export function _getBooking(id: string) {
  return axios.get(`${BOOKINGS_ENDPOINT}/${id}`)
}

export function _exportBookings(query: any) {
  return axios.get(`${BOOKINGS_ENDPOINT}/export`, {params: {...query}})
}

// BOOKING TICKETS
export const BOOKING_TICKETS_ENDPOINT = `/booking-tickets`

export function _postBookingTickets(bookingId, args) {
  return axios.post(`${BOOKINGS_ENDPOINT}/${bookingId}/tickets`, args)
}

export function _getBookingTickets(bookingId, query) {
  return axios.get(`${BOOKINGS_ENDPOINT}/${bookingId}/tickets`, {params: {...query}})
}

export function _getOneBookingTicket(btId) {
  return axios.get(`${BOOKING_TICKETS_ENDPOINT}/one/${btId}`)
}

export function _patchBookingTicket(bookingId, btId, args) {
  return axios.patch(`${BOOKINGS_ENDPOINT}/${bookingId}/tickets/${btId}`, args)
}

export function _getManyBookingTickets(bookingId, query) {
  return axios.get(`${BOOKING_TICKETS_ENDPOINT}/many/${bookingId}`, {params: {...query}})
}

export function _postBookingTransaction(bookingId, args) {
  return axios.post(`${BOOKINGS_ENDPOINT}/${bookingId}/transactions`, args)
}

export function _getBookingTransactions(bookingId, query) {
  return axios.get(`${BOOKINGS_ENDPOINT}/${bookingId}/transactions`, {params: {...query}})
}

export function _shareBookingTicket(bookingId, btId, args) {
  return axios.post(`${BOOKINGS_ENDPOINT}/${bookingId}/tickets/${btId}/send`, args)
}
